import React, { useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import { Typography, useTheme, Divider } from "@mui/material";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import { AiOutlineFullscreen } from "react-icons/ai";
import { AiOutlineAudit } from "react-icons/ai";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { IoMdAnalytics } from "react-icons/io";
import { MdOutlineDashboard } from "react-icons/md";
import { SiBookstack } from "react-icons/si";
import { IoIosPerson } from "react-icons/io";
import { BsShop } from "react-icons/bs";
import { tokens } from "../WMStheme";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  Stack,
  DialogContent,
  IconButton,
} from "@mui/material";
import InBoundCustomer from "./InBoundCustomer";
import InBoundVendor from "./InBoundVendor";
import OutBoundCustomer from "./OutBoundCustomer";
import OutBoundVendor from "./OutBoundVendor";
const CrateMainPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dialogRef = useRef(null);
  const navigate = useNavigate();
  const [fahover, setFaHover] = useState(true);
  const [fahover1, setFaHover1] = useState(true);
  const [showInBoundCustomer, setShowInBoundCustomer] = useState(false);
  
  const handleInBoundCustomer = () => {
   //setShowInBoundCustomer(true);
   navigate("/inbcustomer");
  };
  
  const handleOutBoundVendor = () =>{
    navigate("/outbvendor");
  };
  const handleOutBoundCustomer = () =>{
    navigate("/outbcustomer");
  };
  const handleInBoundVendor = () =>{
    navigate("/inbvendor");
  };
  const handleAudit = () =>{
    navigate("/audit");
  }
  const handleCrateDashBoard = ()=>{
    navigate("/cratedashboard");
  };
  const handleBackdropClick = (event) => {
    // Check if the click event is on the dialog's backdrop and not on the dialog content
    if (dialogRef.current === event.target) {
      setShowInBoundCustomer(false);
    }
  };
  return (
    <div>
      <div
        className="box-content"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "45px",
          marginTop: "1%",
        }}
      >
        <Typography
          variant="h1"
          color={colors.green.DEFAULT}
          style={{
            marginLeft: "1%",
          }}
        >
          Crate Management 
        </Typography>
      </div>

      <div className="row" style={{ marginLeft: "0.1%", marginRight: "0%" }}>
        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem" }}
        >
          <Card
            style={{
              width: "90%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  <AiOutlineFullscreenExit
                    style={{
                      width: "100px",
                      height: "59px",
                      color: "#34519fe6",
                    }}
                  />
                  <Typography
                    variant="h5"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "16px" }}
                  >
                    InBound
                  </Typography>
                </Stack>

                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center">
                <Stack
                  variant="success"
                  onClick={handleInBoundCustomer}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <IoIosPerson
                      fontSize="small"
                      style={{ color: "#34519fe6", cursor: "pointer" }}
                    />
                    {fahover && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "13px", cursor: "pointer" , fontWeight:500}}
                      >
                        Customer
                      </Typography>
                    )}
                  </Stack>
                </Stack>

                <Divider
                  orientation="vertical"
                  flexItem
                  component="ul"
                  sx={{
                    width: 28,
                    height: 28,
                    borderColor: "#080808",
                    zIndex: "999",
                  }}
                  // sx={{  height: '100%' }}
                />

                <Stack
                  variant="success"
                  onClick={handleInBoundVendor}
                  sx={{ marginLeft: "10%" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <BsShop
                      fontSize="small"
                      style={{ color: "#34519fe6", cursor: "pointer" }}
                    />
                    {fahover1 && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "13px", cursor: "pointer", fontWeight:500 }}
                      >
                        Vendor
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </div>

              <Dialog
                 open={showInBoundCustomer}
                onClose={() => setShowInBoundCustomer(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                 onClick={handleBackdropClick}
              >    <DialogTitle sx={{ position: "sticky", height: "4%" }}>
              <IconButton
                aria-label="close"
                onClick={() => setShowInBoundCustomer(false)}
                sx={{
                  position: "absolute",
                  right: 1.9,
                  top: 1,

                  color: "grey",
                }}
              >
                <CloseSharpIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent PaperProps={{ lg: { maxWidth: "10px" } }}>
                 <InBoundCustomer/>
                </DialogContent></Dialog>
            </Card.Body>
          </Card>
        </div>

        <div
           className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem"}}
        >
          <Card
            style={{
              width: "90%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  <AiOutlineFullscreen
                    style={{
                      width: "100px",
                      height: "59px",
                      color: "#34519fe6",
                    }}
                  />
                  <Typography
                    variant="h5"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "16px" }}
                  >
                    OutBound
                  </Typography>
                </Stack>

                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center">
                <Stack
                  variant="success"
                   onClick={handleOutBoundCustomer}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <IoIosPerson
                      fontSize="small"
                      style={{ color: "#34519fe6", cursor: "pointer" }}
                    />
                    {fahover && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "13px", cursor: "pointer" , fontWeight:"500" }}
                      >
                        Customer
                      </Typography>
                    )}
                  </Stack>
                </Stack>

                <Divider
                  orientation="vertical"
                  flexItem
                  component="ul"
                  sx={{
                    width: 28,
                    height: 28,
                    borderColor: "#080808",
                    zIndex: "999",
                  }}
                  // sx={{  height: '100%' }}
                />

                <Stack
                  variant="success"
                  onClick={handleOutBoundVendor}
                  sx={{ marginLeft: "10%" }}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <BsShop
                      fontSize="small"
                      style={{ color: "#34519fe6", cursor: "pointer" }}
                    />
                    {fahover1 && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "13px", cursor: "pointer", fontWeight:"500" }}
                      >
                        Vendor
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </div>

              <Dialog
                // open={showFarmerForm}
                // onClose={() => setShowFarmerForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                // onClick={handleBackdropClick}
              ></Dialog>
            </Card.Body>
          </Card>
        </div>
        
      
        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{ marginBottom: "10rem"}}
        >
          <Card
            style={{
              width: "90%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  <AiOutlineAudit
                    style={{
                      width: "100px",
                      height: "59px",
                      color: "#34519fe6",
                    }}
                  />
                  <Typography
                    variant="h5"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "16px" }}
                  >
                    Audit
                  </Typography>
                </Stack>

                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center"  style={{marginBottom:'3%'}}>
                <Stack
                  variant="success"
                  onClick={handleAudit}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" // Align items in the center horizontally
                  >
                    <SiBookstack
                      fontSize="small"
                      style={{ color: "#34519fe6", cursor: "pointer" }}
                    />
                    {fahover && (
                      <Typography
                        className="ms-2"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "13px", cursor: "pointer", fontWeight:"500" }}
                      >
                        Manage the Crates
                      </Typography>
                    )}
                  </Stack>
                </Stack>

               
              </div>

              <Dialog
                // open={showFarmerForm}
                // onClose={() => setShowFarmerForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                // onClick={handleBackdropClick}
              ></Dialog>
            </Card.Body>
          </Card>
        </div>
        <div
           className="col-lg-3 col-md-3 col-sm-12 mb-3"
          style={{
            marginBottom: "10rem",
            
          }}
        >
          <Card
            style={{
              width: "90%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              // borderRadius: "8%",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                  <IoMdAnalytics
                    style={{
                      width: "100px",
                      height: "59px",
                      color: "#34519fe6",
                    }}
                  />
                  <Typography
                    variant="h5"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "16px" }}
                  >
                    DashBoard
                  </Typography>
                </Stack>

                <Divider
                  sx={{
                    my: 1.1,
                    backgroundColor: "#0B0B0B",
                    color: "#080808",
                    zIndex: "9999",
                    borderBottom: "1px solid #000000",
                  }}
                />
              </Card.Title>
              <div className="d-flex justify-content-center"  style={{marginBottom:'3%'}}>
                <Stack
                  variant="success"
                  onClick={handleCrateDashBoard}
                  style={{ backgroundColor: "white" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center" 
                   // Align items in the center horizontally
                  >
                    <MdOutlineDashboard
                      fontSize="small"
                      style={{ color: "#34519fe6", cursor: "pointer" }}
                    />
                    {fahover && (
                      <Typography
                        className="ms-4"
                        color={colors.green.DEFAULT}
                        sx={{ fontSize: "13px", cursor: "pointer" , fontWeight:"500"}}
                      >
                        Crate Analysis  
                      </Typography>
                    )}
                  </Stack>
                </Stack>

              
              </div>

              <Dialog
                // open={showFarmerForm}
                // onClose={() => setShowFarmerForm(false)}
                PaperProps={{
                  style: {
                    backgroundColor: "#ffffff",
                  },

                  sx: {
                    width: "100%",
                    borderRadius: "19px",
                    overflowX: "hidden",
                    maxWidth: "sm",
                    backgroundSize: "50% auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  },
                }}
                // Set the ref to the dialog to check for clicks on the backdrop
                ref={dialogRef}
                // Attach a click event listener to the backdrop
                // onClick={handleBackdropClick}
              ></Dialog>
            </Card.Body>
          </Card>
        </div>
        
      </div>
    </div>
  );
};

export default CrateMainPage;
