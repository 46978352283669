import React, { useState, useEffect, useMemo, useRef } from "react"
import { styled } from "@mui/material/styles"
import PermIdentityIcon from "@mui/icons-material/PermIdentity"
import { CiCalendarDate } from "react-icons/ci"
import {
  MenuItem,
  Typography,
  Autocomplete,
  createTheme,
  ThemeProvider,
  Input,
  FormHelperText,
  DialogActions,
  Dialog,
  useTheme,
  FormControl,
  InputAdornment,
  Grid,
  Box,
  TextField,
  Button,
  InputLabel,
  Select,
  debounce,
} from "@mui/material"
import SuccessPopUp from "../PopUpMsg/SuccessPopUp"
import InfoPopUp from "../PopUpMsg/InfoDialog"
import ErrorPopUp from "../PopUpMsg/ErrorPopUp"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import { SuccessDialogBox } from "../PopUpMsg/SuccessDialog"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { tokens } from "../WMStheme"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"
import { Navigate, useNavigate } from "react-router-dom"
import axios from "axios"
import { Label } from "semantic-ui-react"
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint"
import DeleteDialog from "../PopUpMsg/DeleteDialog"
import { AlertDialogBox } from "../PopUpMsg/AlertDialog"

const InBoundCustomer = () => {
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
  })
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token")

      if (token) {
        const currentTime = Date.now()
        const exp = localStorage.getItem("exp")

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ")
          config.headers.Authorization = `Bearer ${token}`
          return config
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token")
          localStorage.removeItem("name")
          localStorage.removeItem("user-id")
          localStorage.removeItem("exp")
          localStorage.removeItem("warehouse_data")
          console.log("nvaigate to login page ")
          // Optionally, you can redirect to the login page programmatically
          navigate("/")
        }
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  const [soNumber, setSoNumber] = useState("")
  const [invalidCrates, setInvalidCrates] = useState([])
  const [vendorName, setVendorName] = useState("")
  const [scannedInput, setScannedInput] = useState([])
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [deliveryDate, setDeliveryDate] = useState("")
  const [error, setError] = useState("")
  const [debounceTimer, setDebounceTimer] = useState(null)
  const [infoPopupOpen, setInfoPopupOpen] = useState(false)
  const [errorPopupOpen1, setErrorPopupOpen1] = useState(false)
  const debounceTimerRef = useRef(null)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [infoMessage, setInfoMessage] = useState("")
  const [scannedCrates, setScannedCrates] = useState([])
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [currentInput, setCurrentInput] = useState("")
  const [statusMessage, setStatusMessage] = useState("")
  const [statusColor, setStatusColor] = useState("white")
  const [isScanning, setIsScanning] = useState(false)
  const [selectedWarehouse, setSelectedWarehouse] = useState("")
  const [isDivVisible, setIsDivVisible] = useState(true)
  const [isScanComplete, setIsScanComplete] = useState(false)
  const [soData, setSoData] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [errorPopupOpen, setErrorPopupOpen] = useState(false)
  const [isloading, setIsLoading] = useState(false)
  const [totalCrates, setTotalCrates] = useState([])
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const [errorMessage4, setErrorMessage4] = useState("")
  const typography = tokens(theme.palette.mode.typography)
  const handleHideDiv = () => {
    setIsDivVisible(false)
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [location, setLocation] = useState("Cold Storage 1")
  // const [isLocationFilled , setIsLocationFilled] = useState(false);
  const handleDelete = (index) => {
    const crateElement = scannedInput[index]
    const updatedCrates = scannedCrates.filter(
      (c) => c.barcode !== crateElement?.crate_code
    )
    const updatedInput = scannedInput.filter(
      (c) => c.crate_code !== crateElement?.crate_code
    )

    setScannedCrates(updatedCrates)
    setScannedInput(updatedInput)
    setIsScanComplete(false)
  }

  const handleDialogOpen = (index) => {
    console.log("delete icon click")
    setSelectedIndex(index)
    setIsDialogOpen(true)
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false)
    setSelectedIndex(null)
  }

  const handleConfirmDelete = () => {
    if (selectedIndex !== null) {
      handleDelete(selectedIndex)
    }
    handleDialogClose()
  }

  // useEffect(()=> {
  //   if(!isLocationFilled){
  //     setInfoMessage("Location of Warehouse is mandatory");
  //     setInfoPopupOpen(true);
  //   }
  // }, [])

  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7) // Set date to one week ago

  const endDateTime = new Date() // Current date and time

  const globalTheme = useTheme()

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#000",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#000", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  )

  const handleBeginScan = () => {
    setIsScanning(true)
    setIsScanComplete(false)
    setStatusMessage("Ready to scan crates...")
    setStatusColor("Gray")
    setIsDivVisible(true)
    setIsTableVisible(false)
  }

  const handleEndScan = () => {
    setIsScanning(false)
    setIsScanComplete(true)
    setIsTableVisible(true)
    setStatusMessage("Scanning stopped.")
    setStatusColor("gray")
    setIsDivVisible(false)
  }

  // const handleFetchCust = async () => {
  //     if (scannedCrates.length === 0) {
  //       alert("Please enter SO Number and scan at least one crate.")
  //       return
  //     }
  //     const payload = {
  //       crates_code: totalCrates,
  //     }
  //     console.log("Payload being sent:", payload);
  //     try {
  //       const endpoint =
  //         API_ENDPOINTS.POST_WMS_CRATE_MANAGEMENT_INBOUND_CUSTOMER_FETCH_CNAME;

  //       const response = await axiosInstance.post(endpoint, payload, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         validateStatus: (status) => status === 200 || status === 400,
  //       })
  //       console.log("Response >>>>>>>>>", response)
  //       if (response.status == 200) {
  //         const data = response.data
  //         console.log(">>>>>>>>>this is data ", data)
  //         const updatedCrates = data?.data?.data?.map((crate) => ({
  //           crate_code: crate.crate_code,
  //           name : crate.name,
  //           isValid: Boolean(crate.reference_code),
  //         }))
  //         setScannedCrates(updatedCrates);

  //         const validCrates = updatedCrates.filter((crate) => crate.isValid);
  //         const invalidCrates = updatedCrates.filter((crate) => !crate.isValid);

  //         console.log("Updated Crates >>>>>>>" , updatedCrates)
  //         setTotalCrates(validCrates);
  //       } else if (response.status === 400) {
  //         console.log("400 Response: Bad Request")
  //         const data = response.data
  //         console.log(">>>>>>>>>this is data ", data)
  //         const updatedCrates = data?.data?.data?.map((crate) => ({
  //           crate_code: crate.crate_code,
  //           name : crate.name,
  //           isValid: Boolean(crate.reference_code),
  //         }))

  //         const invalidCrates = response.data?.invalid_crates || []
  //         console.log("total Crates:", totalCrates)
  //         console.log("Invalid Crates:", invalidCrates)
  //         setErrorMessage4(
  //           `The highlighted crates are invalid for InBound. Please delete them and resubmit to proceed.`
  //         )
  //         markInvalidCrates(invalidCrates)
  //         setErrorPopupOpen1(true)

  //         setInvalidCrates(invalidCrates)
  //       }
  //     } catch (error) {
  //       console.error("Error:", error)
  //       setIsLoading(false)
  //     }
  //   }

  const handleFetchCust = async () => {
    if (scannedCrates.length === 0) {
      alert("Please enter SO Number and scan at least one crate.")
      return
    }
    const payload = {
      crates_code: totalCrates,
    }
    console.log("Payload being sent:", payload)

    try {
      const endpoint =
        API_ENDPOINTS.POST_WMS_CRATE_MANAGEMENT_INBOUND_CUSTOMER_FETCH_CNAME

      const response = await axiosInstance.post(endpoint, payload, {
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: (status) => status === 200 || status === 400,
      })
      console.log("Response >>>>>>>>>", response)

      const { data } = response.data
      const invalidCrates = response.data?.invalid_crates || []

      const validCrates = data.map((crate) => ({
        crate_code: crate.crate_code,
        name: crate.name,
        isValid: true,
      }))

      const invalidCratesProcessed = invalidCrates.map((crate_code) => ({
        crate_code,
        name: null,
        isValid: false,
      }))

      const combinedCrates = [...validCrates, ...invalidCratesProcessed]

      console.log("Combined Crates >>>>>>>", combinedCrates)

      setScannedInput(combinedCrates)
      setTotalCrates(validCrates)
      setInvalidCrates(invalidCratesProcessed)
      setIsSubmitButtonDisabled(false)
      if (response.status === 400) {
        setErrorMessage4(
          `The highlighted crates are invalid for Outbound. Please delete them and resubmit to proceed.`
        )
        markInvalidCrates(invalidCrates)
        setErrorPopupOpen1(true)
        setIsSubmitButtonDisabled(true)
      }
    } catch (error) {
      console.error("Error:", error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isScanning) return

    const handleGlobalKeyInput = (event) => {
      if (
        event.key === "Shift" ||
        event.key === "Control" ||
        event.key === "Alt"
      ) {
        return
      }
      if (event.key === "Enter" && currentInput.trim() !== "") {
        const isSuccess = true
        const newCrate = { barcode: currentInput.trim(), isValid: isSuccess }

        setScannedCrates((prevCrates) => [...prevCrates, newCrate])
        setStatusMessage(`Crate "${currentInput.trim()}" scanned successfully`)
        setStatusColor("green")

        setCurrentInput("")
        setTimeout(() => {
          setStatusMessage("Ready to scan crates...")
          setStatusColor("Gray")
        }, 2000)
      } else if (event.key !== "Enter") {
        setCurrentInput((prev) => prev + event.key)
      }
    }

    window.addEventListener("keydown", handleGlobalKeyInput)
    return () => {
      window.removeEventListener("keydown", handleGlobalKeyInput)
    }
  }, [isScanning, currentInput])
  //to convert epoch to dd/mmy/yyy
  const formatDateIST = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined
    if (!isValidTimestamp) {
      return ""
    }
    const date = new Date(parseInt(epochTimestamp, 10))
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    return `${day}/${month}/${year}`
  }
  const handleSubmit = async () => {
    if (!location) {
      alert("Please enter location")
      return
    }
    if (scannedCrates.length === 0) {
      alert("Please enter SO Number and scan at least one crate.")
      return
    }
    const payload = {
      reference: location,
      crates_code: totalCrates,
    }

    try {
      const endpoint = API_ENDPOINTS.POST_WMS_CRATE_MANAGEMENT_INBOUND_CUSTOMER
      const response = await axiosInstance.post(endpoint, payload, {
        headers: {
          "Content-Type": "application/json",
        },
        // Treat 400 as a valid response to handle it explicitly
        validateStatus: (status) => status === 200 || status === 400,
      })

      if (response.status == 200) {
        const data = response.data
        setSuccessMessage(
          ` ${scannedCrates.length} Crates SuccessFully Received from the Customer, click on Proceed to navigate`
        )
        setSuccessDialogOpen(true)
        setSuccessPopupOpen(true)
        // setSuccessMessage(`Crate Marked as Returned, Click Okay to Navigate`)
        // setSuccessPopupOpen(true)
        setIsLoading(false)
        console.log("Response:", data)
      } else if (response.status === 400) {
        console.log("400 Response: Bad Request")
        const invalidCrates = response.data?.invalid_crates || []
        console.log("total Crates:", totalCrates)
        console.log("Invalid Crates:", invalidCrates)
        setErrorMessage4(
          `The highlighted crates are invalid for this customer. Please delete them and resubmit to proceed.`
        )
        setErrorPopupOpen1(true)
        markInvalidCrates(invalidCrates)
        setInvalidCrates(invalidCrates)
      }
    } catch (error) {
      console.error("Error:", error)
      setIsLoading(false)
    }
    console.log("Submitted Data:", payload)
  }

  const markInvalidCrates = (invalidCrates) => {
    const updatedCrates = scannedCrates.map((crate) => ({
      ...crate,
      isValid: !invalidCrates.includes(crate.barcode),
    }))
    setScannedCrates(updatedCrates)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: colors.green.DEFAULT,
      color: colors.green.DEFAULT,
      fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 1,
      // backgroundColor:" #d50000",
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))
  useEffect(() => {
    const cratesCode = scannedCrates?.map((crate) => crate.barcode)
    setTotalCrates(cratesCode)
  }, [scannedCrates])

  const ScannedCratesTable = ({ scannedInput }) => {
  
    return (
      <TableContainer
        component={Paper}
        sx={{ height: 430, overflowY: "auto" }}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: 300,
            "& .MuiTableCell-body": {
              padding: "1px 1px",
            },
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">SNo.</StyledTableCell>
              <StyledTableCell align="center">Crate Code</StyledTableCell>
              <StyledTableCell align="center">Customer Name</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scannedInput?.map((crate, index) => {
              useEffect(() => {
                setIsSubmitButtonDisabled(!crate.isValid)
              }, [crate])
              return (
                <StyledTableRow
                  key={index}
                  style={{
                    backgroundColor: crate.isValid ? "white" : "#ffcccb",
                  }}
                >
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">
                    {crate.crate_code}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {crate?.name !== undefined && crate?.name !== null
                      ? crate?.name
                      : "N/A"}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Button
                      onClick={() => handleDialogOpen(index)}
                      color="secondary"
                    >
                      <IconButton
                        aria-label="delete"
                        sx={{ padding: 0.3 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
        <DeleteDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          onConfirm={handleConfirmDelete}
          title="Delete Confirmation"
          message="Are you sure you want to delete this crate?"
          confirmLabel="Delete"
          cancelLabel="Cancel"
        />
      </TableContainer>
    )
  }
  console.log("Scanned Crates >>>>", scannedCrates)
  console.log("Scanned Input >>>>>>>>", scannedInput)

  const HalfTable = ({ scannedInput }) => {
    const tableContainerRef = useRef(null)

    useEffect(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop =
          tableContainerRef.current.scrollHeight
      }
    }, [scannedInput])
    return (
      <TableContainer
        component={Paper}
        sx={{ height: 450, overflowY: "auto" }}
        ref={tableContainerRef}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: 300,
            "& .MuiTableCell-body": {
              padding: "1px 1px",
            },
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">SNo.</StyledTableCell>
              <StyledTableCell align="center">Crate Code</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scannedCrates?.map((crate, index) => (
              <StyledTableRow
                key={index}
                style={{ backgroundColor: crate.isValid ? "white" : "#ffcccb" }}
              >
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">
                  {crate.barcode}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const navigate = useNavigate()
  const handleButtonClick = () => {
    navigate("/crate")
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10px",
        paddingRight: "10px",
        width: "100%",
      }}
    >
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1%",
          marginBottom: "0.5%",
        }}
      >
        <Typography
          variant="h1"
          color={colors.green.DEFAULT}
        >
          InBound Customer
        </Typography>
        <Button
          variant="outlined"
          startIcon={
            <ArrowBackIcon
              style={{ color: colors.green.DEFAULT, fontSize: 26 }}
            />
          }
          sx={{
            borderColor: colors.green.DEFAULT,
            color: colors.green.DEFAULT,
          }}
          onClick={handleButtonClick}
        >
          <Typography
            variant="h5"
            color={colors.green.DEFAULT}
          >
            Back
          </Typography>
        </Button>
      </Grid>

      <Box
        p={2}
        bgcolor="#ffff"
        height="12.5vh"
        borderRadius="10px"
        boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
      >
        <Grid
          container
          spacing={1.2}
          sx={{ marginTop: "0%", marginLeft: "0%" }}
        >
          <Grid
            item
            xs={12}
            sm={2.4}
            md={2.4}
          >
            <FormControl fullWidth>
              <InputLabel>Location Selection for Storage</InputLabel>
              <Select
                label="Location Selection for Storage"
                fullWidth
                required
                size="medium"
                displayEmpty
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value)
                }}
                inputProps={{ "aria-label": "Type" }}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
              >
                <MenuItem
                  value=""
                  disabled
                >
                  Select Location
                </MenuItem>
                <MenuItem value="Cold Storage 1">Cold Storage 1</MenuItem>
                <MenuItem value="Cold Storage 2">Cold Storage 2</MenuItem>
                <MenuItem value="Cold Storage 3">Cold Storage 3</MenuItem>
                <MenuItem value="Cold Storage 4">Cold Storage 4</MenuItem>
                <MenuItem value="Cold Storage 5">Cold Storage 5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <br></br>
      <Box
        p={2}
        bgcolor="#ffff"
        height="auto"
        borderRadius="10px"
        boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            gap: "20px",
            marginBottom: "20px",
            // backgroundColor: "#ffebee",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor:
                isScanning || isScanComplete ? "gray" : "#34519fe6",
              color: "#ffffff",
            }}
            size="large"
            onClick={handleBeginScan}
            disabled={isScanning || isScanComplete}
          >
            Begin Scan
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: isScanning ? "#34519fe6" : "gray",
              color: "#ffffff",
            }}
            size="large"
            onClick={() => {
              handleFetchCust()
              handleEndScan()
              handleHideDiv()
            }}
            disabled={!isScanning}
          >
            End Scan
          </Button>
          <div style={{ marginTop: "0.1%" }}>
            <Label
              style={{
                backgroundColor: "#EF1818FF",
                color: "white",
                fontWeight: 400,
                fontSize: "18px",
                fontFamily: "Lexend Deca",
                cursor: "pointer",
                width: "auto",
              }}
              tag
            >
              Total Crates Scanned : {scannedCrates?.length}
            </Label>
          </div>

          <Button
            variant="contained"
            sx={{
              backgroundColor: isScanning ? "gray" : "#34519fe6",
              color: "#ffffff",
            }}
            onClick={handleSubmit}
            size="large"
            disabled={isSubmitButtonDisabled}
          >
            Submit
          </Button>
        </Box>

        {isDivVisible && (
          <>
            <div style={{ display: "flex" }}>
              <ThemeProvider theme={tableTheme}>
                <div
                  style={{ alignItems: "left", width: "50%", display: "flex" }}
                >
                  <HalfTable
                    scannedInput={scannedInput}
                    setScannedInput={setScannedInput}
                  />
                </div>
              </ThemeProvider>
              <div
                style={{
                  display: "flex",
                  padding: "10px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "57vh",
                  width: "60%",
                  backgroundColor: statusColor,
                  color: "#fff",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                {statusMessage || "Contains data"}
              </div>
            </div>
          </>
        )}

        {isTableVisible && scannedCrates.length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            <ThemeProvider theme={tableTheme}>
              <ScannedCratesTable
                scannedInput={scannedInput}
                setScannedInput={setScannedInput}
              />
            </ThemeProvider>
          </div>
        )}
      </Box>
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)
        }}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
      <SuccessDialogBox
        open={successDialogOpen}
        okayText="Proceed"
        handleOkayClick={() => {
          setSuccessDialogOpen(false)
          navigate("/crate")
        }}
        onClose={() => setSuccessDialogOpen(false)}
        content={successMessage}
      />
      <InfoPopUp
        open={infoPopupOpen}
        onClose={() => setInfoPopupOpen(false)}
        message={infoMessage}
      />
      <AlertDialogBox
        open={errorPopupOpen1}
        okayText="Proceed"
        handleOkayClick={() => setErrorPopupOpen1(false)}
        onClose={() => setErrorPopupOpen1(false)}
        content={errorMessage4}
      />
    </Box>
  )
}

export default InBoundCustomer
