import React, { useState, useEffect,  useRef } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  MenuItem,
  Typography,  
  useTheme,
  FormControl,
  InputAdornment,
  Grid,Select,
  InputLabel,
  Box,
  TextField,
  Button,
  debounce,
  FormHelperText,
} from "@mui/material";

import { ScaleLoader } from "react-spinners";

import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { tokens } from "../WMStheme";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import StrollerIcon from '@mui/icons-material/Stroller';
import DescriptionIcon from '@mui/icons-material/Description';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import { CiCalendarDate } from "react-icons/ci";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import GenerateBarCode from "./GenerateBarCode";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

const InBoundVendor = () => {
  
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
    overflowY: "hidden",
    // backgroundColor: "red",
  };
  const theme = useTheme();
  const [poNumber, setPoNumber] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [error, setError] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null);
  const debounceTimerRef = useRef(null);
  const [scannedCrates, setScannedCrates] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [currentInput, setCurrentInput] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [statusColor, setStatusColor] = useState("white");
  const [isScanning, setIsScanning] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [isDivVisible, setIsDivVisible] = useState(true);
  const [isScanComplete, setIsScanComplete] = useState(false);
  const[poData, setPoData] =useState([]);
  const[cratesNumber, setCratesNumber] =useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [errorPopupOpen1, setErrorPopupOpen1] = useState(false);
  const [errorMessage4, setErrorMessage4] = useState("");
  const[isloading,  setIsLoading]=useState(false);
  const[barcodeData, setBarCodeData]=useState([]);
  const colors = tokens(theme.palette.mode);
  const[totalCrates,setTotalCrates] =useState([]);
  const [disabled,setDisabled] = useState(false);
  const [touchedFields, setTouchedFields] = useState({ location: false, crate_count: false });

  const typography = tokens(theme.palette.mode.typography);
  const handleHideDiv = () => {
    setIsDivVisible(false);
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleDelete = (index) => {
    const updatedCrates = [...scannedCrates];
    updatedCrates.splice(index, 1);
    setScannedCrates(updatedCrates);
  };
  const [loading, setLoading] = useState(false);
  const [submitAction, setSubmitAction] = useState(false);
  const [crateformData, setCrateFormData] = useState({
    colour: 'red',
    type: 'vendor',
    size: 'medium',
    location: '',
    crate_count: '',
    description: '',
    reference_code : poNumber,    
  });
   
  const [showBarcodeComponent, setShowBarcodeComponent] = useState(false);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/crate");
  };
  const formatDateIST = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 30); // Set date to one week ago

  const endDateTime = new Date(); // Current date and time

  

  const fetchDetails = async (poNumber) => {
    if (!poNumber) {
      setVendorName("");
      
      return;
    }
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_WMS_PO_LIST, {
        params: {
          start_date: oneWeekAgo.getTime(),
          end_date: endDateTime.getTime(),
          limit: 50,
          int_po_no: poNumber,
          offset: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      
      if (response.status == 200) {
        const data = await response?.data?.Data;
        setPoData(data?.po_list[0]);
        console.log("FILTER DATA FOR SO -------->", data?.po_list[0]);
        setIsLoading(false);

      } 
      
    } catch (error) {
      console.log("Error fetching data:", error);
      setErrorMessage(error);
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };

  useEffect(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      fetchDetails(poNumber);
    }, 1000);

    return () => {
      clearTimeout(debounceTimerRef.current);
    };
  }, [poNumber]);
  const handleChange = (field, value) => {
    setCrateFormData((prevState) => {
      const numericValue = Number(value);
  
      let validatedValue = value;
      if (field === "crate_count") {
        validatedValue = Math.max(0, Math.min(200, numericValue)); // Restrict crate_count to 0-200
      }
      
      return {
        ...prevState,
         [field]:field === "crate_count" ? validatedValue : value,   
      };

      if (field === "crate_count" || field === "location") {
        setTouchedFields((prev) => ({ ...prev, [field]: true }));
      }
    });
  };

  const handleBlur = (field) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
  };
  
  const handleSubmit = async () => {
     console.log('crateformData in handleSubmit:', crateformData);
    setLoading(true);
    setDisabled(true);
    try {
      const endpoint = API_ENDPOINTS.POST_WMS_CRATE_MANAGEMENT_INBOUND_VENDOR;
      const response = await axiosInstance.post(endpoint,crateformData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      let barCodeData;

      if (response.status = 200 ) {
      const data = response.data?.data.codes_array;
      console.log('Data->:', data);
      setBarCodeData(data); 
      barCodeData = data;
      setLoading(false);
      console.log('barcodeData : ' , data);
      
      }
      //Form submitted successfully
      
      //generating barcodes
      if (barCodeData.length === 0) {
        alert('No barcodes available to download');
        return;
      }
      setShowBarcodeComponent(true);
     // Delay navigation by 2 seconds
    // setTimeout(() => {
    //   navigate("/crate");
    // }, 6000);
    } catch (error) {
       console.error('Error:', error);
       setLoading(false);
       setDisabled(false);
     // alert('Failed to submit form');
    }
  };
  

  const handleCompletion = () => {
    setShowBarcodeComponent(false); // Reset the component after processing
  };
 
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: colors.green.DEFAULT,
      color: colors.green.DEFAULT,
      fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 1,
      // backgroundColor:" #d50000",
    },
  }))
 
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1%",
          marginBottom: "15px",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          InBound Vendor
        </Typography>
        <Button
          variant="outlined"
          startIcon={
            <ArrowBackIcon
              style={{ color: colors.green.DEFAULT, fontSize: 26 }}
            />
          }
          sx={{
            borderColor: colors.green.DEFAULT,
            color: colors.green.DEFAULT,
          }}
          onClick={handleButtonClick}
        >
          <Typography variant="h5" color={colors.green.DEFAULT}>
            Back
          </Typography>
        </Button>
      </Grid>
      {loading ? (
        <div style={loaderContainerStyle}>
          <ScaleLoader color="#495057" loading={loading} />
        </div>
      ) :(
        <Box
        p={2}
        bgcolor="#ffff"
        borderRadius="10px"
        boxShadow="0px 0px 10px rgba(143, 25, 25, 0.1)"
>
  {/* First Row: PO Number, Location, Total Crates, Description */}
  <Grid container spacing={2} sx={{ marginTop: "0%", marginLeft: "0.8%" }}>
    {/* PO Number */}
    <Grid item xs={12} sm={4} md={3}>
      <FormControl fullWidth variant="standard">
        <TextField
          id="outlined-basic-so-number"
          label="Enter PO Number*"
          variant="outlined"
          value={poNumber}
          onChange={(e) => {
            setPoNumber(e.target.value);
            handleChange("reference_code", e.target.value);
          }}
        />
      </FormControl>
    </Grid>

    {/* Location */}
    <Grid item xs={12} sm={4} md={3}>
      <FormControl 
      fullWidth 
      required
      error={touchedFields.location && !crateformData?.location}
      >
        <InputLabel>Select Location</InputLabel>
        <Select
          label="Select Location"
          value={crateformData?.location}
          onChange={(e) => handleChange("location", e.target.value)}
          onBlur={() => handleBlur("location")}
          fullWidth
          size="medium"
          inputProps={{ "aria-label": "Type" }}
          startAdornment={
            <InputAdornment position="start"></InputAdornment>
          }
        >
          <MenuItem value="" disabled>
            Select Location
          </MenuItem>
          <MenuItem value="coldstorage 1">ColdStorage 1</MenuItem>
          <MenuItem value="coldstorage 2">ColdStorage 2</MenuItem>
          <MenuItem value="coldstorage 3">ColdStorage 3</MenuItem>
          <MenuItem value="coldstorage 4">ColdStorage 4</MenuItem>
          <MenuItem value="coldstorage 5">ColdStorage 5</MenuItem>
        </Select>
        {touchedFields.location && !crateformData?.location && (
          <FormHelperText>Please select a location</FormHelperText>
        )}
      </FormControl>
    </Grid>

   {/* Total Crates */}
    <Grid item xs={12} sm={4} md={3}>
      <FormControl fullWidth>
        <TextField
          size="medium"
          fullWidth
          required
          error={
            touchedFields.crate_count && 
            (crateformData?.crate_count === undefined ||
              crateformData?.crate_count < 1 ||
              crateformData?.crate_count > 200)
          }
          label="Total Crates"
          variant="outlined"
          type="number"
          value={crateformData?.crate_count || ""}
          onChange={(e) => {
            const value = parseInt(e.target.value, 10);
            handleChange("crate_count", value); // Update the form data
          }}
          onBlur={() => handleBlur("crate_count")} // Mark as touched on blur
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <StrollerIcon />
              </InputAdornment>
            ),
          }}
        />
        {/* Error Message */}
        {touchedFields.crate_count &&
          (crateformData?.crate_count === undefined ||
            crateformData?.crate_count < 1 ||
            crateformData?.crate_count > 200) && (
            <Typography
              variant="body2"
              color="error"
              sx={{ marginTop: "4px", marginLeft: "4px" }}
            >
              {crateformData?.crate_count === undefined
                ? "Total Crates is required."
                : "Total Crates must be between 1 and 200."}
            </Typography>
          )}
      </FormControl>
    </Grid>

   {/* Description */}
   <Grid item xs={12} sm={8} md={3}  sx={{ display: 'flex', alignItems: 'center', marginTop: '-16px'  }}>
      <TextField
        label="Description"
        name="description"
        type="text"
        value={crateformData?.description}
        onChange={(e) => handleChange("description", e.target.value)}
        size="medium"
        fullWidth
        margin="normal"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  </Grid>

  {/* Scond Row: colour,size,Warehouse Name */}
  <Grid container spacing={2} sx={{ marginTop: "1%", marginLeft: "0.8%" }}>
     {/* Select Color */}
     <Grid item xs={12} sm={2.2} md={4}>
      <FormControl fullWidth>
        <InputLabel>Select Color</InputLabel>
        <Select
          label="select color"
          value={crateformData?.colour}
          onChange={(e) => handleChange('colour', e.target.value)}
          fullWidth
          size="medium"
          displayEmpty
          inputProps={{ 'aria-label': 'Select Color' }}
          startAdornment={
            <InputAdornment position="start">
              <ColorLensIcon />
            </InputAdornment>
          }
        >
          
          <MenuItem value="red">Red</MenuItem>
          <MenuItem value="blue">Blue</MenuItem>
          <MenuItem value="gray">Gray</MenuItem>
          <MenuItem value="black">Black</MenuItem>
          <MenuItem value="orange">Orange</MenuItem>
          <MenuItem value="yellow">Yellow</MenuItem>
        </Select>
      </FormControl>
        </Grid>
      {/* Select Size */}
      <Grid item xs={12} sm={2.2} md={4}>
        <FormControl fullWidth>
        <InputLabel>Size</InputLabel>
        <Select
        label="size"
        name="size"
        value={crateformData?.size}
        onChange={(e) => handleChange('size', e.target.value)}
        fullWidth
        size="medium"
        margin="normal"
        variant="outlined"
        displayEmpty
        inputProps={{
          'aria-label': 'Select Size',
        }}
        startAdornment={
          <InputAdornment position="start">
            <IconButton edge="start" size="medium" disabled>
              <AspectRatioIcon />
            </IconButton>
          </InputAdornment>
        }
        >
        <MenuItem value="small">Small</MenuItem>
        <MenuItem value="medium">Medium</MenuItem>
        <MenuItem value="large">Large</MenuItem>
        </Select>
        </FormControl>
    </Grid>
    {/* Warehouse Name */}
    <Grid item xs={12} sm={4} md={4}>
      <FormControl fullWidth>
        <TextField
          size="medium"
          fullWidth
          label="Warehouse Name"
          variant="outlined"
          value={poData?.wh_name}
          readOnly
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      </FormControl>
    </Grid>
  </Grid>

  {/* Third Row: Vendor Name, Vendor Code, Delivery Date */}
  <Grid container spacing={2} sx={{ marginTop: "1%", marginLeft: "0.8%" }}>
    {/* Vendor Name */}
    <Grid item xs={12} sm={4} md={4}>
      <FormControl fullWidth>
        <TextField
          size="medium"
          fullWidth
          label="Vendor Name"
          variant="outlined"
          value={poData?.vendor_name}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon />
              </InputAdornment>
            ),
            readOnly:true,
          }}
        />
      </FormControl>
    </Grid>

    {/* Vendor Code */}
    <Grid item xs={12} sm={4} md={4}>
      <FormControl fullWidth>
        <TextField
          size="medium"
          fullWidth
          label="Vendor Code"
          variant="outlined"
          value={poData?.vendor_code}
          readOnly
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon />
              </InputAdornment>
            ),
            readOnly:true,
          }}
        />
      </FormControl>
    </Grid>

    {/* Delivery Date */}
    <Grid item xs={12} sm={4} md={3.8}>
      <FormControl fullWidth>
        <TextField
          id="outlined-basic-delivery-date"
          label="Delivery Date"
          variant="outlined"
          readOnly
          value={formatDateIST(poData?.delivery_date)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CiCalendarDate />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Grid>
  </Grid>

  {/* Submit Button */}
  <Grid container spacing={2} sx={{ marginTop: "1%", marginLeft: "0.8%" }}>
    <Grid item xs={12}>
      <Button
        variant="contained"
        sx={{
          borderColor: colors.green.DEFAULT,
          backgroundColor: colors.green.DEFAULT,
        }}
        disabled={
          disabled ||
          loading || 
         !(crateformData?.reference_code &&
          crateformData?.location && 
          crateformData?.crate_count >= 1 && crateformData?.crate_count <= 200)
        }
        onClick={handleSubmit}
      >
        <Typography variant="h7" color={colors.white.DEFAULT}>
        Download Barcodes here
        </Typography>
      </Button>
      {showBarcodeComponent && (
            <GenerateBarCode barcodeData={barcodeData} onComplete={handleCompletion} poNumber={poNumber} />
          )}
    </Grid>
  </Grid>
</Box>

      )}
      <SuccessPopUp
    open={successPopupOpen}
    onClose={() => setSuccessPopupOpen(false)}
    message={successMessage}
  />
  <ErrorPopUp
    open={errorPopupOpen}
    onClose={() => setErrorPopupOpen(false)}
    message={errorMessage}
  />
{
  barcodeData?.length > 0 
  && 
  <TableContainer
        component={Paper}
        sx={{ height: 510, overflowY: "auto" }}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: 300,
            "& .MuiTableCell-body": {
              padding: "1px 1px",
            },
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">SNo.</StyledTableCell>
              <StyledTableCell align="center">Barcode</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {barcodeData?.map((code, index) => (
              <StyledTableRow
                key={index}
                style={{ backgroundColor:"white" }}
              >
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">
                  {code}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
}

    </div>
    
  );
};

export default InBoundVendor;