import React, { useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';
import { Document, Page, View, Image, StyleSheet } from '@react-pdf/renderer';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column', // Vertical alignment
    padding: 10,
    alignItems: 'center', // Center align all barcodes
  },
  barcodeContainer: {
    width: 200, // Larger barcode width
    height: 100, // Larger barcode height
    marginBottom: 20, // Spacing between barcodes
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const GenerateBarCode = ({ barcodeData, onComplete, poNumber='' }) => {
  const isGenerated = useRef(false); // Track if PDF has been generated

  useEffect(() => {
    if (isGenerated.current) return; // Prevent re-execution
    isGenerated.current = true;

    if (!Array.isArray(barcodeData) || barcodeData.length === 0) {
      alert('No barcodes available to generate');
      onComplete();
      return;
    }

    const generateBarcodeImage = (data) => {
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, data, {
        format: 'CODE128',
        width: 4, // Adjust barcode line width
        height: 150, // Adjust barcode height
        displayValue: true, // Include human-readable text
      });
      return canvas.toDataURL('image/png');
    };

    const generateAndDownloadPDF = async () => {
      const barcodeImages = barcodeData.map(generateBarcodeImage);

      const BarcodeDocument = () => (
        <Document>
          <Page size="A4" style={styles.page}>
            {barcodeImages?.map((imgSrc, index) => (
              <View key={index} style={styles.barcodeContainer}>
                <Image src={imgSrc} />
              </View>
            ))}
          </Page>
        </Document>
      );

      // Generate PDF and save it
      const blob = await pdf(<BarcodeDocument />).toBlob();
      let savedAsFileName = '';
      poNumber ? savedAsFileName += `${poNumber}_PO_barcodes.pdf` : savedAsFileName += 'barcodes.pdf';
      saveAs(blob, savedAsFileName);
      onComplete();
    };

    generateAndDownloadPDF();
  }, [barcodeData, onComplete]);

  return null; // No UI for this component
};

export default GenerateBarCode;
