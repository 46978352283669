import React, { useState, useEffect } from "react"
import CrateDashBoardItem from "./CrateDashboardItem"
import { Button, Typography, useTheme } from "@mui/material"
import axios from "axios"
import { tokens } from "../../WMStheme"
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint"
import { ScaleLoader } from "react-spinners"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate } from "react-router-dom"

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
})

const CrateDashBoard = () => {
  const [loading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState({})
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token")

      if (token) {
        const currentTime = Date.now()
        const exp = localStorage.getItem("exp")

        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ")
          config.headers.Authorization = `Bearer ${token}`
          return config
        } else {
          localStorage.removeItem("token")
          localStorage.removeItem("name")
          localStorage.removeItem("user-id")
          localStorage.removeItem("exp")
          localStorage.removeItem("warehouse_data")
          console.log("navigate to login page ")

          navigate("/")
        }
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
    width: "100%",
    overflowY: "hidden",
  }
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  useEffect(() => {
    const fetchDashboardData = async () => {
      setIsLoading(true)
      try {
        const response = await axiosInstance.get(
          `${API_ENDPOINTS.GET_WMS_CRATE_MANAGEMENT_DASHBOARD_GET_DATA}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        if (response.status >= 200 && response.status < 305) {
          const data = response?.data?.data
          console.log("data: ", data[0])
          setDashboardData(data[0])
          console.log("Fetched Data:", data)
        } else {
          console.error("Error fetching details")
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchDashboardData()
  }, [])

  const navigate = useNavigate()
  const handleButtonClick = () => {
    navigate("/crate")
  }

  return (
    <div>
      <div
        className="box-content"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "45px",
          marginTop: "1%",
          marginBottom: "0.5%",
          padding: "0 1%",
        }}
      >
        <Typography
          variant="h1"
          color={colors.green.DEFAULT}
          style={{
            marginLeft: "1%",
          }}
        >
          Crate Dashboard
        </Typography>
          
        <Button
          variant="outlined"
          startIcon={
            <ArrowBackIcon
              style={{ color: colors.green.DEFAULT, fontSize: 26 }}
            />
          }
          sx={{
            borderColor: colors.green.DEFAULT,
            color: colors.green.DEFAULT,
          }}
          onClick={handleButtonClick}
        >
          <Typography
            variant="h5"
            color={colors.green.DEFAULT}
          >
            Back
          </Typography>
        </Button>
      </div>
      <Typography
        variant="h4"
        color="gray"
        style={{
          marginLeft: "1%",
          marginBottom: "1%",
        }}
      >
        View and manage all crate-related data in one place. Easily track
        statuses and details.
      </Typography>
      <div
        className="row"
        style={{ marginLeft: "0.1%", marginRight: "0%" }}
      >
        {loading ? (
          <div style={loaderContainerStyle}>
            <ScaleLoader
              color="#495057"
              loading={loading}
            />
            <Typography
              variant="h5"
              color="gray"
              style={{ marginTop: "1rem" }}
            >
              Loading Crate Data, please wait...
            </Typography>
          </div>
        ) : (
          Object.entries(dashboardData)?.map(([key, value]) => (
            <CrateDashBoardItem
              key={key}
              data={{ key, value }}
            />
          ))
        )}
      </div>
    </div>
  )
}

export default CrateDashBoard
