import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Link } from "react-router-dom";
import SaveAlert from "../../SaveAlert";
import { IoIosResize } from "react-icons/io";
import { FaBars, FaTimes } from "react-icons/fa"; // Import the appropriate icons
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa"; // Import arrow icons
import { FaHandHoldingDollar } from "react-icons/fa6";
import { GiCargoCrate } from "react-icons/gi";

import { FaArrowLeft } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import ApiErrorAlert from "../../ApiErrorAlert";
import HomeIcon from "@mui/icons-material/Home";
import { IoIosListBox } from "react-icons/io";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import DescriptionIcon from "@mui/icons-material/Description";
import InventoryIcon from "@mui/icons-material/Inventory";
import logoClosed from "../../Assets/Famboremovebg.png";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import logoOpen from "../../Assets/NewLogo.png";
import { useNavigate } from "react-router-dom";
import Configuration from "../../Assets/Config.png";
import { IoLogOut } from "react-icons/io5";
import { BiSolidReport } from "react-icons/bi";

import { FaUsers } from "react-icons/fa";
import "./SidebarWMS.css";

const SidebarWMS = ({
  isExpanded,
  setIsExpanded,
  setSidebarWidth,
  sidebarWidth,
  handleSidebarToggle,
  setLoginComplete,
}) => {
  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const [isCircleExpanded, setIsCircleExpanded] = useState(false);

  const [isWMSDialogOpen, setIsWMSDialogOpen] = useState(false);
  const [openLogoSource, setOpenLogoSource] = useState("");
  const [closedLogoSource, setClosedLogoSource] = useState("");

  const navigate = useNavigate();
  var stored_user_access = JSON.parse(localStorage.getItem("user_access"));
  console.log("user_access retrieved from localStorage: ", stored_user_access);

  const userDetail = stored_user_access ? stored_user_access : null;
  const handleWMSLogout = () => {
    setIsWMSDialogOpen(false);
    console.log("logout is in progress");
    localStorage.clear();
    setLoginComplete(false);
     navigate("/");
  };
  useEffect(() => {
    // Assuming logoOpen and logoClosed are the URLs of your images
    const initialOpenLogoSource = logoOpen;
    const initialClosedLogoSource = logoClosed;

    // Store both initial image sources in localStorage
    localStorage.setItem("openLogoSource", initialOpenLogoSource);
    localStorage.setItem("closedLogoSource", initialClosedLogoSource);
    setOpenLogoSource(initialOpenLogoSource);
    setClosedLogoSource(initialClosedLogoSource);
  }, []);
  useEffect(() => {
    // Retrieve the image sources from localStorage
    const storedOpenLogoSource = localStorage.getItem("openLogoSource");
    const storedClosedLogoSource = localStorage.getItem("closedLogoSource");

    // Display the images
    if (openLogoSource && closedLogoSource) {
      setOpenLogoSource(storedOpenLogoSource);
      setClosedLogoSource(storedClosedLogoSource);
      // You can create img elements or set them in the component state for rendering
      // For simplicity, let's just log the sources here
      console.log("Open Logo Source:", openLogoSource);
      console.log("Closed Logo Source:", closedLogoSource);
    }
  }, []);
  const handleMouseEnter = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  const handleWMSLogoutConfirmDialog = () => {
    setIsWMSDialogOpen(true);
  };

  const handleWMSDialogClose = () => {
    setIsWMSDialogOpen(false);
  };
  const circleContainerStyle = {
    left: isExpanded ? "11%" : "4%", // Adjust as needed
    top: isExpanded ? "-58%" : "-58%", // Adjust as needed
    marginBottom: "9%", // Adjust as needed
  };
  const ToggleIcon = ({ isExpanded }) => {
    return isExpanded ? (
      <FaArrowLeft style={{ fontSize: "20px", fontWeight: 800 }} />
    ) : (
      <FaArrowRight style={{ fontSize: "20px", fontWeight: 800 }} />
    );
  };
  const createMenuItem = ({ to, icon, text, onClick }) => {
    return (
      <Link
        to={to}
        className="sidebarwms-icon"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <div
          className="sidebarwms-icon-text"
          style={{ display: "flex", alignItems: "center" }}
        >
          {isExpanded && (
            <>
              <div className="icon-wrapper" onClick={onClick}>
                <div style={{ marginLeft: "6px" }}>{icon}</div>
                <Typography
                  className="icon-name"
                  style={{
                    fontSize: "15px",
                    textDecoration: "none",
                    color: "#ffffff",
                    marginLeft: "5px",
                    marginTop: "-20px",
                  }}
                >
                  {text}
                </Typography>
              </div>
            </>
          )}
        </div>
        {!isExpanded && icon}
      </Link>
    );
  };
  // Assuming logoOpen and logoClosed are the URLs of your images

  const handleMouseLeave = (event) => {
    if (event.relatedTarget && event.relatedTarget.classList) {
      const isHoveringSidebar =
        event.relatedTarget?.classList?.contains("sidebarwms");
      if (!isHoveringSidebar && isExpanded) {
        setIsExpanded(false);
      }
    }
  };
  return (
    <>
      <Box
        className={`sidebarwms ${isExpanded ? "expanded" : ""}`}
        sx={{ width: isExpanded ? "150px" : "50px" }}
      >
        <div className="sidebarwms-logo">
          {isExpanded ? (
            <img
              src={openLogoSource}
              alt="logo"
              className="logo-image logo-open"
              style={{ filter: "invert(100%) saturate(0) brightness(2000%)" }}
            />
          ) : (
            <img
              src={closedLogoSource}
              alt="logo"
              className="logo-image logo-closed"
              style={{ filter: "invert(100%) saturate(0) brightness(2000%)" }}
            />
          )}
          {isExpanded && <div className="green-line" />}
        </div>
        <div
          className={`circle-container ${
            isCircleExpanded ? "expanded" : "collapsed"
          }`}
          style={circleContainerStyle}
          onClick={() => {
            setIsCircleExpanded(!isCircleExpanded);
            handleSidebarToggle(); // Assuming this function toggles the overall sidebar
          }}
        >
          <div className="sidebarwms-circle">
            <ToggleIcon isExpanded={isCircleExpanded} />
          </div>
        </div>

        <Divider
          // variant="middle"
          // fullwidth
          sx={{
            marginTop: "2px",
            marginBottom: "15px",
            marginRight: "6px",
            marginLeft: "5px",
            backgroundColor: "#ffffff",
            // marginRight: "30px",
            height: "2px",
            zIndex: "9999",
          }}
        />

        <div className="sidebarwms-icons">
          {/* {createMenuItem({
            to: "/home",
            icon: <HomeIcon style={{ fontSize: 20, marginBottom: "20px", width: "20px", height: "20px" }} />,
            text: "Home",
            onClick: () => navigate("/home"),
          })} */}
          {userDetail?.m_home && (
          <MenuItem
            to="/home"
            icon={
              <HomeIcon
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="Home"
            isExpanded={isExpanded}
          /> )}
          {userDetail?.m_config && (
          <MenuItem
            to="/configuration"
            icon={
              <img
                src={Configuration}
                className={`recipeimg ${isExpanded ? "expanded" : ""}`}
                alt="Recipe"
                style={{
                  width: "20px",
                  height: "20px",
                  marginBottom: "20px",
                }}
              />
            }
            text="Masters"
            isExpanded={isExpanded}
          /> )}
          {userDetail?.m_purchase_order && (
          <MenuItem
            to="/po"
            icon={
              <MenuBookIcon
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="Purchase"
            isExpanded={isExpanded}
          /> )}
          {userDetail?.m_orders && (
          <MenuItem
            to="/so"
            icon={
              <IoIosListBox
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="Orders"
            isExpanded={isExpanded}
          /> )}
           {userDetail?.m_orders && (
          <MenuItem
            to="/crate"
            icon={
              <GiCargoCrate
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="Crates"
            isExpanded={isExpanded}
          /> )}
    {userDetail?.m_inventory && (
          <MenuItem
            to="/inventory"
            icon={
              <InventoryIcon
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="Inventory"
            isExpanded={isExpanded}
          />

          )}
          {userDetail?.m_cycle_count && (
          <MenuItem
            to="/cyclecount"
            icon={
              <DescriptionIcon
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="Cycle Count"
            isExpanded={isExpanded}
          />
          )}
          {userDetail?.m_stock_transfer && (
          <MenuItem
            to="/STO"
            icon={
              <FaMoneyBillTransfer
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="Transfer"
            isExpanded={isExpanded}
          />
          )}
          {userDetail?.m_reports && (
          <MenuItem
            to="/reports"
            icon={
              <BiSolidReport
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="Reports"
            isExpanded={isExpanded}
          />
          )}
          
          {userDetail?.m_profit_n_loss && (
          <MenuItem
            to="/pnl"
            icon={
              <FaHandHoldingDollar
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="P&L Analysis"
            isExpanded={isExpanded}
          />
          )}
          
          {userDetail?.m_user_management && (     
<MenuItem
  to="/users"
  icon={
    <FaUsers
      style={{
        fontSize: 20,
        marginBottom: "20px",
        width: "20px",
        height: "20px",
      }}
    />
  }
  text="Users"
  isExpanded={isExpanded}
/>
)}
          {userDetail?.m_integration && (
          <MenuItem
            to="/integration"
            icon={
              <IntegrationInstructionsIcon
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            text="Integration"
            isExpanded={isExpanded}
          />
          )}
          <MenuItem
            icon={
              <IoLogOut
                style={{
                  fontSize: 20,
                  marginBottom: "20px",
                  width: "20px",
                  height: "20px",
                }}
                onClick={handleWMSLogoutConfirmDialog}
              />
            }
            text="Logout"
            isExpanded={isExpanded}
            onClick={handleWMSLogoutConfirmDialog}
          />
          
        </div>
       
        <Dialog open={isWMSDialogOpen} onClose={handleWMSDialogClose}>
          <DialogTitle>Logout Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to logout from WMS?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleWMSDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleWMSLogout} color="primary">
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <SaveAlert
        open={apiSuccessOpen}
        onClose={() => setApiSuccessOpen(false)} // Close the alert
      />
      <ApiErrorAlert
        open={apiErrorOpen}
        onClose={() => setApiErrorOpen(false)} // Close the alert
      />
    </>
  );
};
const MenuItem = ({ to, icon, text, isExpanded, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    // You can perform additional logic here if needed
  };

  return (
    <Link
      to={to}
      className="sidebarwms-icon"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <div
        className="sidebarwms-icon-text"
        style={{ display: "flex", alignItems: "center" }}
      >
        {isExpanded && (
          <>
            <div className="icon-wrapper" onClick={onClick}>
              <div style={{ marginLeft: "6px" }}>{icon}</div>

              <Typography
                className="icon-name"
                style={{
                  fontSize: "15px",
                  textDecoration: "none",
                  color: "#ffffff",
                  marginLeft: "5px",
                  marginTop: "-20px",
                }}
              >
                {text}
              </Typography>
            </div>
          </>
        )}
      </div>
      {!isExpanded && icon}
    </Link>
  );
};
export default SidebarWMS;
