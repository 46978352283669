const initialState = {
    action: '',
   auditData: null,
  };
  export const auditAddNewCrateReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_AUDIT_CRATE_ACTION':
        return {
          action: action.payload.action,
          auditData: action.payload.auditData,
        };
      default:
        return state;
    }
  };