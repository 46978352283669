import React, { useState, useEffect, useMemo, useRef } from "react"
import { styled } from "@mui/material/styles"
import {
  MenuItem,
  Typography,
  Autocomplete,
  createTheme,
  ThemeProvider,
  Input,
  FormHelperText,
  useTheme,
  FormControl,
  InputAdornment,
  Grid,
  Box,
  TextField,
  Button,
  debounce,
} from "@mui/material"
import SuccessPopUp from "../PopUpMsg/SuccessPopUp"
import InfoPopUp from "../PopUpMsg/InfoDialog"
import { SuccessDialogBox } from "../PopUpMsg/SuccessDialog"
import ErrorPopUp from "../PopUpMsg/ErrorPopUp"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { tokens } from "../WMStheme"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Label } from "semantic-ui-react"
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint"
import DeleteDialog from "../PopUpMsg/DeleteDialog"
import { AlertDialogBox } from "../PopUpMsg/AlertDialog"
import { UpdateSharp } from "@mui/icons-material"

const OutBoundVendor = () => {
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
  })
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token")

      if (token) {
        const currentTime = Date.now()
        const exp = localStorage.getItem("exp")

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ")
          config.headers.Authorization = `Bearer ${token}`
          return config
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token")
          localStorage.removeItem("name")
          localStorage.removeItem("user-id")
          localStorage.removeItem("exp")
          localStorage.removeItem("warehouse_data")
          console.log("nvaigate to login page ")
          // Optionally, you can redirect to the login page programmatically
          navigate("/")
        }
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  const [soNumber, setSoNumber] = useState("")
  const [vendorName, setVendorName] = useState("")
  const [deliveryDate, setDeliveryDate] = useState("")
  const [error, setError] = useState("")
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [infoMessage, setInfoMessage] = useState("")
  const [debounceTimer, setDebounceTimer] = useState(null)
  const debounceTimerRef = useRef(null)
  const [scannedCrates, setScannedCrates] = useState([])
  const [scannedInput, setScannedInput] = useState([])
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [currentInput, setCurrentInput] = useState("")
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [statusMessage, setStatusMessage] = useState("")
  const [statusColor, setStatusColor] = useState("white")
  const [isScanning, setIsScanning] = useState(false)
  const [selectedWarehouse, setSelectedWarehouse] = useState("")
  const [isDivVisible, setIsDivVisible] = useState(true)
  const [isScanComplete, setIsScanComplete] = useState(false)
  const [soData, setSoData] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [errorPopupOpen, setErrorPopupOpen] = useState(false)
  const [errorPopupOpen1, setErrorPopupOpen1] = useState(false)
  const [errorMessage4, setErrorMessage4] = useState("")
  const [infoPopupOpen, setInfoPopupOpen] = useState(false)
  const [isloading, setIsLoading] = useState(false)
  const [invalidCrates, setInvalidCrates] = useState([])
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const [totalCrates, setTotalCrates] = useState([])
  const typography = tokens(theme.palette.mode.typography)
  const handleHideDiv = () => {
    setIsDivVisible(false)
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)

  const handleDelete = (index) => {
    const crateElement = scannedInput[index]
    const updatedCrates = scannedCrates.filter(
      (c) => c.barcode !== crateElement?.crate_code
    )
    const updatedInput = scannedInput.filter(
      (c) => c.crate_code !== crateElement?.crate_code
    )

    setScannedCrates(updatedCrates)
    setScannedInput(updatedInput)
    setIsScanComplete(false)
  }

  useEffect(() => {
    const cratesCode = scannedCrates?.map((crate) => crate.barcode)
    setTotalCrates(cratesCode)
  }, [scannedCrates])

  const handleDialogOpen = (index) => {
    setSelectedIndex(index)
    setIsDialogOpen(true)
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false)
    setSelectedIndex(null)
  }

  const handleConfirmDelete = () => {
    if (selectedIndex !== null) {
      handleDelete(selectedIndex)
    }
    handleDialogClose()
  }

  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7) // Set date to one week ago

  const endDateTime = new Date() // Current date and time

  const globalTheme = useTheme()

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#ffffff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            variant: "contained", //customize typography styles for all buttons in table by default
            fontSize: "0.8rem",
            color: "#000",
          },
          fontFamily: "Lexend Deca", // Add your desired font family here
          fontWeightRegular: 400, // Set the desired font weight for regular entries
          fontWeightBold: 700, // Set the desired font weight for bold entries
          fontSize: 12,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "#000", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  )

  const fetchDetails = async (soNumber) => {
    if (!soNumber) {
      setVendorName("")
      setDeliveryDate("")
      return
    }
    setIsLoading(true)
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_WMS_SO_LIST, {
        params: {
          start_date: oneWeekAgo.getTime(),
          end_date: endDateTime.getTime(),
          limit: 50,
          int_so_no: soNumber,
          offset: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status == 200) {
        const data = await response?.data?.Data
        setSoData(data?.so_list[0])
        console.log("FILTER DATA FOR SO -------->", data?.so_list[0])
        setIsLoading(false)
      }
    } catch (error) {
      console.log("Error fetching data:", error)
      setErrorMessage(error)
      setIsLoading(false)
      setErrorPopupOpen(true)
    }
  }

  useEffect(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current)
    }

    debounceTimerRef.current = setTimeout(() => {
      fetchDetails(soNumber)
    }, 1000)

    return () => {
      clearTimeout(debounceTimerRef.current)
    }
  }, [soNumber])

  const handleSoNumberChange = (event) => {
    setSoNumber(event.target.value)
  }

  const handleBeginScan = () => {
    setIsScanning(true)
    setIsScanComplete(false)
    setStatusMessage("Ready to scan crates...")
    setStatusColor("Gray")
    setIsDivVisible(true)
    setIsTableVisible(false)
  }

  useEffect(() => {
    setInfoMessage("Enter Crates Here")
    setInfoPopupOpen(true)
  }, [])

  const handleEndScan = () => {
    setIsScanning(false)
    setIsScanComplete(true)
    setIsTableVisible(true)
    setStatusMessage("Scanning stopped.")
    setStatusColor("gray")
    setIsDivVisible(false)
  }

  useEffect(() => {
    if (!isScanning) return

    const handleGlobalKeyInput = (event) => {
      if (
        event.key === "Shift" ||
        event.key === "Control" ||
        event.key === "Alt"
      ) {
        return
      }
      if (event.key === "Enter" && currentInput.trim() !== "") {
        const isSuccess = true
        const newCrate = { barcode: currentInput.trim(), isValid: isSuccess }

        setScannedCrates((prevCrates) => [...prevCrates, newCrate])
        setStatusMessage(`Crate "${currentInput.trim()}" scanned successfully`)
        setStatusColor("green")

        setCurrentInput("")
        setTimeout(() => {
          setStatusMessage("Ready to scan crates...")
          setStatusColor("Gray")
        }, 2000)
      } else if (event.key !== "Enter") {
        setCurrentInput((prev) => prev + event.key)
      }
    }

    window.addEventListener("keydown", handleGlobalKeyInput)
    return () => {
      window.removeEventListener("keydown", handleGlobalKeyInput)
    }
  }, [isScanning, currentInput])
  //to convert epoch to dd/mmy/yyy
  const formatDateIST = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined
    if (!isValidTimestamp) {
      return ""
    }
    const date = new Date(parseInt(epochTimestamp, 10))
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    return `${day}/${month}/${year}`
  }

  const handleFetchPo = async () => {
    if (scannedCrates.length === 0) {
      alert("Please enter SO Number and scan at least one crate.")
      return
    }
    const payload = {
      crates_code: totalCrates,
    }
    console.log("Payload being sent:", payload)

    try {
      const endpoint =
        API_ENDPOINTS.POST_WMS_CRATE_MANAGEMENT_OUTBOUND_VENDOR_FETCH_DATA

      const response = await axiosInstance.post(endpoint, payload, {
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: (status) => status === 200 || status === 400,
      })
      console.log("Response >>>>>>>>>", response)

      const { data } = response.data
      const invalidCrates = response.data?.invalid_crates || []

      const validCrates = data.map((crate) => ({
        crate_code: crate.crate_code,
        reference_code: crate.reference_code,
        isValid: true,
      }))

      const invalidCratesProcessed = invalidCrates.map((crate_code) => ({
        crate_code,
        reference_code: null,
        isValid: false,
      }))

      const combinedCrates = [...validCrates, ...invalidCratesProcessed]

      console.log("Combined Crates >>>>>>>", combinedCrates)

      setScannedInput(combinedCrates)
      setTotalCrates(validCrates)
      setInvalidCrates(invalidCratesProcessed)
      setIsSubmitButtonDisabled(false)
      if (response.status === 400) {
        setErrorMessage4(
          `The highlighted crates are invalid for Outbound. Please delete them and resubmit to proceed.`
        )
        markInvalidCrates(invalidCrates)
        setErrorPopupOpen1(true)
        setIsSubmitButtonDisabled(true)
      }
    } catch (error) {
      console.error("Error:", error)
      setIsLoading(false)
    }
  }

  // if (response.status == 200) {
  //   const data = response.data
  //   console.log(">>>>>>>>>this is data ", data)
  //   const updatedCrates = data.data.map((crate) => ({
  //     crate_code: crate.crate_code,
  //     reference_code: crate.reference_code,
  //   }))
  //   setScannedInput(updatedCrates)

  //   const validCrates = updatedCrates.filter((crate) => crate.isValid)
  //   const invalidCrates = response.data.data?.invalid_crates || [];

  //   console.log("Updated Crates >>>>>>>", updatedCrates)
  //   console.log("Invalid Crates >>>>>>>", invalidCrates);
  //   setTotalCrates(validCrates)
  //   setInvalidCrates(invalidCrates);

  // } else

  //     if (response.status === 400) {
  //       const validCrates = updatedCrates.filter((crate) => crate.isValid)
  //       // const invalidCrates = response.status === 400 ? response.data?.invalid_crates || updatedCrates.filter((crate)=>!crate.isValid) : updatedCrates.filter((crate) => !crate.isValid);
  //       console.log("400 Response: Bad Request")
  //       console.log("4000 >>>> ", response)
  //       const data = response.data
  //       const updatedCrates = data.data.map((crate) => ({
  //         crate_code: crate.crate_code,
  //         reference_code: crate.reference_code,

  //       }))
  //       console.log("UPDATED CRATES >>>>>>>>>>", updatedCrates)
  //       setScannedInput(updatedCrates)
  //       const invalidCrates = response.data?.data?.invalid_crates || []
  //       console.log("total Crates:", totalCrates)
  //       console.log("Invalid Crates:>>>>>>>>", invalidCrates)
  //       setErrorMessage4(
  //         `The highlighted crates are invalid for Outbound. Please delete them and resubmit to proceed.`
  //       )
  //       markInvalidCrates(invalidCrates)
  //       setErrorPopupOpen1(true)

  //       setInvalidCrates(invalidCrates)
  //     }
  //   } catch (error) {
  //     console.error("Error:", error)
  //     setIsLoading(false)
  //   }
  // }

  console.log("Scanned Input :>>>>>>>>>>", scannedInput)

  const handleSubmit = async () => {
    if (scannedCrates.length === 0) {
      alert("Please enter SO Number and scan at least one crate.")
      return
    }

    const payload = {
      crates_code: totalCrates,
    }
    console.log("handle submit payload ", payload)

    try {
      const endpoint = API_ENDPOINTS.POST_WMS_CRATE_MANAGEMENT_OUTBOUND_VENDOR
      const response = await axiosInstance.post(endpoint, payload, {
        headers: {
          "Content-Type": "application/json",
        },
        // Treat 400 as a valid response to handle it explicitly
        validateStatus: (status) => status === 200 || status === 400,
      })

      if (response.status == 200) {
        const data = response.data
        setInvalidCrates([])
        setErrorPopupOpen1(false)
        setErrorMessage4("")
        setSuccessMessage(
          `${scannedCrates.length} Crates Successfully Returned to Vendor, click Proceed to navigate`
        )
        setSuccessDialogOpen(true)
        setSuccessPopupOpen(true)
        setIsLoading(false)
        console.log("Response:", data)
      } else if (response.status === 400) {
        console.log("400 Response: Bad Request")
        const invalidCrates = response.data?.data?.invalid_crates || []
        console.log("total Crates:", totalCrates)
        console.log("Invalid Crates:", invalidCrates)
        setErrorMessage4(
          `The highlighted crates are invalid for Outbound. Please delete them and resubmit to proceed.`
        )

        markInvalidCrates(invalidCrates)
        setErrorPopupOpen1(true)
        setInvalidCrates(invalidCrates)
      }
    } catch (error) {
      console.error("Error:", error)
      setIsLoading(false)
    }

    console.log("Submitted Data:", payload)
  }

  const markInvalidCrates = (invalidCrates) => {
    const updatedCrates = scannedCrates.map((crate) => ({
      ...crate,
      isValid: !invalidCrates.includes(crate.barcode),
    }))
    setScannedCrates(updatedCrates)
    // setScannedInput(updatedCrates)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: colors.green.DEFAULT,
      color: colors.green.DEFAULT,
      fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 1,
      // backgroundColor:" #d50000",
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  const HalfTable = ({ scannedInput }) => {
    const tableContainerRef = useRef(null)

    useEffect(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop =
          tableContainerRef.current.scrollHeight
      }
    }, [scannedInput])
    return (
      <TableContainer
        component={Paper}
        sx={{ height: 550, overflowY: "auto" }}
        ref={tableContainerRef}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: 300,
            "& .MuiTableCell-body": {
              padding: "1px 1px",
            },
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">SNo.</StyledTableCell>
              <StyledTableCell align="center">Crate Code</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scannedCrates?.map((crate, index) => (
              <StyledTableRow
                key={index}
                style={{ backgroundColor: crate.isValid ? "white" : "#ffcccb" }}
              >
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">
                  {crate.barcode}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const ScannedCratesTable = ({ scannedInput }) => {

    return (
      <TableContainer
        component={Paper}
        sx={{ height: 545, overflowY: "auto" }}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: 300,
            "& .MuiTableCell-body": {
              padding: "1px 1px",
            },
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">SNo.</StyledTableCell>
              <StyledTableCell align="center">Crate Code</StyledTableCell>
              <StyledTableCell align="center">PO number</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scannedInput?.map((crate, index) => {
              console.log("Crates Data >>>>>>>:", crate)
              useEffect(() => {
                setIsSubmitButtonDisabled(!crate.isValid)
              }, [crate])
              return (
                <StyledTableRow
                  key={index}
                  style={{
                    backgroundColor: crate.isValid ? "white" : "#ffcccb",
                  }}
                >
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">
                    {crate.crate_code}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {crate?.reference_code !== undefined &&
                    crate?.reference_code !== null
                      ? crate?.reference_code
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      onClick={() => handleDialogOpen(index)}
                      color="secondary"
                    >
                      <IconButton
                        aria-label="delete"
                        sx={{ padding: 0.3 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
        <DeleteDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          onConfirm={handleConfirmDelete}
          title="Delete Confirmation"
          message="Are you sure you want to delete this crate?"
          confirmLabel="Delete"
          cancelLabel="Cancel"
        />
      </TableContainer>
    )
  }

  const navigate = useNavigate()
  const handleButtonClick = () => {
    navigate("/crate")
  }

  return (
    <Box>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1%",
          marginBottom: "15px",
        }}
      >
        <Typography
          variant="h1"
          color={colors.green.DEFAULT}
        >
          OutBound Vendor
        </Typography>
        <Button
          variant="outlined"
          startIcon={
            <ArrowBackIcon
              style={{ color: colors.green.DEFAULT, fontSize: 26 }}
            />
          }
          sx={{
            borderColor: colors.green.DEFAULT,
            color: colors.green.DEFAULT,
          }}
          onClick={handleButtonClick}
        >
          <Typography
            variant="h5"
            color={colors.green.DEFAULT}
          >
            Back
          </Typography>
        </Button>
      </Grid>

      <Box
        p={2}
        bgcolor="#ffff"
        height="auto"
        borderRadius="10px"
        boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            gap: "20px",
            marginBottom: "20px",
            // backgroundColor: "#ffebee",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor:
                isScanning || isScanComplete ? "gray" : "#34519fe6",
              color: "#ffffff",
            }}
            size="large"
            onClick={handleBeginScan}
            disabled={isScanning || isScanComplete}
          >
            Begin Scan
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: isScanning ? "#34519fe6" : "gray",
              color: "#ffffff",
            }}
            size="large"
            onClick={() => {
              handleFetchPo()
              handleEndScan()
              handleHideDiv()
            }}
            disabled={!isScanning}
          >
            End Scan
          </Button>
          <div style={{ marginTop: "0.1%" }}>
            <Label
              style={{
                backgroundColor: "#EF1818FF",
                color: "white",
                fontWeight: 400,
                fontSize: "18px",
                fontFamily: "Lexend Deca",
                cursor: "pointer",
                width: "auto",
              }}
              tag
            >
              Total Crates Scanned : {scannedCrates?.length}
            </Label>
          </div>

          <Button
            variant="contained"
            sx={{
              backgroundColor: isScanning ? "gray" : "#34519fe6",

              color: "#ffffff",
            }}
            onClick={handleSubmit}
            size="large"
            disabled={isSubmitButtonDisabled}
          >
            Submit
          </Button>
        </Box>

        {isDivVisible && (
          <>
            <div style={{ display: "flex" }}>
              <ThemeProvider theme={tableTheme}>
                <div
                  style={{ alignItems: "left", width: "40%", display: "flex" }}
                >
                  <HalfTable
                    scannedInput={scannedInput}
                    setScannedInput={setScannedInput}
                  />
                </div>
              </ThemeProvider>

              <div
                style={{
                  display: "flex",
                  padding: "10px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70.5vh",
                  width: "60%",
                  backgroundColor: statusColor,
                  color: "#fff",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                {statusMessage || "Contains data"}
              </div>
            </div>
          </>
        )}

        {isTableVisible && scannedInput.length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            <ThemeProvider theme={tableTheme}>
              <ScannedCratesTable
                scannedInput={scannedInput}
                setScannedInput={setScannedInput}
              />
            </ThemeProvider>
          </div>
        )}

        {scannedCrates.length > 0}
      </Box>
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
      <InfoPopUp
        open={infoPopupOpen}
        onClose={() => setInfoPopupOpen(false)}
        message={infoMessage}
      />
      <SuccessDialogBox
        open={successDialogOpen}
        okayText="Proceed"
        handleOkayClick={() => {
          setSuccessDialogOpen(false)
          navigate("/crate")
        }}
        onClose={() => {
          setSuccessDialogOpen(false)
        }}
        content={successMessage}
      />
      <AlertDialogBox
        open={errorPopupOpen1}
        okayText="Proceed"
        handleOkayClick={() => setErrorPopupOpen1(false)}
        onClose={() => setErrorPopupOpen1(false)}
        content={errorMessage4}
      />
    </Box>
  )
}

export default OutBoundVendor
