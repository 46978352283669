
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { pdf } from "@react-pdf/renderer"; // This will allow us to generate PDFs programmatically
import FileSaver from "file-saver"; // For saving the file
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InvoiceFile from "../Reports/InvoiceFile";
import { useTheme } from "@mui/material";
import { tokens } from "../WMStheme";
import { Progress, Spin } from "antd";
import { ScaleLoader } from "react-spinners";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

const BulkAllocationDownloadPopUp = ({ setOpenBulkAllocationDialog, soIds }) => {
  // console.log("soIds in bulk invoice download popup",soIds);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [responseMessage, setResponseMessage] = useState("In Progress");
  const [isLoading, setIsLoading] = useState(false);
  const [percent, setPercent] = useState(3);
  const [invoiceData, setInvoiceData] = useState([]);

  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        if (currentTime <= exp) {
          console.log("comparing the current time and exp time");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("navigate to login page");
          navigate("/wms");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

//   console.log("so_id in InvoiceProgressPopUp", soIds);

  useEffect(() => {
   // console.log("fetch invoice details function in useeffect");
    fetchInvoiceDetails(soIds);
  }, []);

//   useEffect(() => {
//     if (invoiceData?.po_no) {
//       console.log("PO No found in invoiceData:", invoiceData.po_no);
//       sendPoNo(invoiceData.po_no);
//     } else {
//       console.log("No PO No found in invoiceData");
//     }
//   }, [invoiceData]);
const [hasDownloaded, setHasDownloaded] = useState(false);

useEffect(() => {
  if (invoiceData && invoiceData.length > 0 && !hasDownloaded) {
    downloadInvoices(invoiceData);
    setHasDownloaded(true); // Set the flag to prevent further downloads
  }
}, [invoiceData, hasDownloaded]);

  const fetchInvoiceDetails = async (soIds) => {
    console.log("soIds in ftch invoice data function ",soIds);
    setIsLoading(true);
    const urlWithParams = `${API_ENDPOINTS.GET_WMS_SO_BULK_INVOICES_DOWNLOAD}?so_ids=${soIds.join(',')}`;
  
    try {
      const response = await axiosInstance.put(
        urlWithParams,
       {headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setInvoiceData(response?.data?.data);
        console.log("invoice data function ");
        //downloadInvoices(response?.data?.data);
        // generateAndDownloadPDF(0); // Start generating the first PDF
        let currentPercent = 1;
        const interval = setInterval(() => {
          currentPercent += 2;
          if (currentPercent >= 100) {
            setPercent(100);
            clearInterval(interval);
          } else {
            setPercent(currentPercent);
          }
        }, 50);
        setTimeout(() => {
          clearInterval(interval);
        }, 6000);
      }
    } catch (error) {
      setIsLoading(false);
      setResponseMessage("Some Error Occured, Try Again!");
    }
  };
  const downloadInvoices = async (invoices) => {
    // Use a Set to track downloaded order numbers to avoid duplicates
    const downloadedOrders = new Set();
    
    for (const invoice of invoices) {
      if (!downloadedOrders.has(invoice.order_no)) {
        await createPDFAndDownload(invoice);
        downloadedOrders.add(invoice.order_no); // Mark this order as downloaded
      } else {
        console.warn(`Duplicate download attempt for: ${invoice.order_no}`);
      }
    }
  };
  const createPDFAndDownload = async (invoice) => {
    console.log(`Generating PDF for: ${invoice.order_no}`);
    const pdfBlob = await createPDF(invoice);
    const url = window.URL.createObjectURL(pdfBlob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = `${invoice.order_no}_so.pdf`;
    document.body.appendChild(a);
    a.click();
    console.log(`Downloaded: ${invoice.order_no}_so.pdf`);
    document.body.removeChild(a);
    
    window.URL.revokeObjectURL(url);
    
    await new Promise(resolve => setTimeout(resolve, 3000)); // Optional delay
  };
  
  
  const createPDF = async (invoice) => {
    // Create the PDF and return the blob
    return new Promise((resolve) => {
      const pdfBlob = pdf(<InvoiceFile invoiceData={invoice} />).toBlob();
      resolve(pdfBlob);
    });
  };
//   const generateAndDownloadPDF = async (index) => {
//     if (index >= invoiceData.length) {
//       setResponseMessage("All PDFs downloaded!");
//       return;
//     }

//     const invoice = invoiceData[index];
//     const pdfBlob = await pdf(<InvoiceFile invoiceData={invoice} />).toBlob(); // Generate PDF as Blob
//     FileSaver.saveAs(pdfBlob, `${invoice.order_no}_so.pdf`); // Automatically download the file

//     // Update the progress bar
//     setPercent(((index + 1) / invoiceData.length) * 100);

//     // Proceed to the next invoice after a short delay
//     setTimeout(() => {
//       generateAndDownloadPDF(index + 1);
//     }, 500); // Optional: Short delay to avoid overwhelming the user with downloads
//   };

  const sendPoNo = async (poNo) => {
    const data = {
      poNo: poNo,
    };

    try {
      const response = await axiosInstance.post(API_ENDPOINTS.POST_PO_NO, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 200 && response.status < 305) {
        console.log("PO No sent successfully:", response.data);
      } else {
        console.error("Error sending PO No:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending PO No:", error);
    }
  };

  const handleClose = () => {
    setOpenBulkAllocationDialog(false);
    //setOpenDialog(false);
  };

//   console.log(
//     "INVOICE DATA-------------",
//     typeof invoiceData?.shipping_address,
//     invoiceData?.shipping_address
//   );

  return (
    <Dialog
      maxWidth="md"
      open={true}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "30%",
          height: "35%",
        },
      }}
    >
      <DialogTitle sx={{ position: "sticky", height: "3%" }}>
        <Typography
          variant="h3"
          color={colors.green.DEFAULT}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {percent === 100 ? "Report Generated!" : responseMessage}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 1.9,
            top: 1,
            color: "grey",
          }}
        >
          <CloseSharpIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "55px",
              zIndex: "1",
            }}
          >
            <ScaleLoader color={colors.green.DEFAULT} loading={isLoading} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Progress
              strokeColor={colors.green.DEFAULT}
              type="circle"
              percent={percent}
            />
          </div>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
      {percent === 100 && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.green.DEFAULT,
          }}
          onClick={handleClose}
        >
          <Typography
            variant="h6"
            color={colors.white.DEFAULT}
            fontFamily="Lexend Deca"
          >
            Close
          </Typography>
        </Button>
      )}

      </DialogActions>
    </Dialog>
  );
};

export default BulkAllocationDownloadPopUp;

