
import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { BeatLoader } from "react-spinners";
import { ClipLoader } from "react-spinners";

import giphy from "../src/Assets/giphy-1--unscreen.gif";
import "./LogoAnimation.css";

const PageNotFound = () => {
  const storedDetails = localStorage?.getItem("details");
  const userDetails = storedDetails ? JSON.parse(storedDetails) : null;
  const isUserDetailsNull = userDetails ? false : true;

  const [showLoading, setShowLoading] = useState(true);
  const [countdown, setCountdown] = useState(3);
  const progress = (5 - countdown) * (100 / 5);
console.log("not fund component called!!!!");
  useEffect(() => {
    if (!isUserDetailsNull) {
      const redirectTimeout = setTimeout(() => {
        window.location.href = "/home";
      }, 3000);

      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearTimeout(redirectTimeout);
        clearInterval(countdownInterval);
      };
    } else {
      const redirectTimeout = setTimeout(() => {
        window.location.href = "/";
      }, 1000);
      return () => {
        clearTimeout(redirectTimeout);
      };
    }
  }, []);

  useEffect(() => {
    if (!isUserDetailsNull) {
      setShowLoading(false);
    }
  }, []);

  const animatedBackground = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100,
    backgroundSize: "cover",
    backgroundImage: "linear-gradient(to top, #ACE1AF, #FFFFFF)",
    animation: "animateBackground 4s ease-in-out infinite",
  };

  const containerStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
    zIndex: 9999,
  };

  return showLoading ? (
    <Box style={containerStyle}>
      <div style={animatedBackground} />
      <img
        src={giphy}
        alt="logo"
        style={{
          zIndex: 9999,
        }}
      />
      <BeatLoader
        speedMultiplier="0.5"
        color="#2C7B55"
        style={{
          zIndex: 9999,
        }}
      />
    </Box>
  ) : (
    <Box style={containerStyle}>
      <ErrorOutlineOutlined sx={{ fontSize: 100, color: "red" }} />
      <Typography variant="h4" color="textPrimary" mt={2}>
        Page Not Found
      </Typography>
      <Typography variant="body1" color="textSecondary" mt={1}>
        The requested page could not be found.
      </Typography>
      <Typography variant="body2" color="textSecondary" mt={2}>
        Redirecting you back in {countdown} seconds...
      </Typography>
      {/* <CircularProgress
        size={30}
        color="#2c7B55"
        variant="determinate"
        value={progress}
        sx={{ marginTop: "10px" }}
      /> */}
    </Box>
  );
};
const UnknownPage = () => {
  console.log("UnknownPage is in progress");
  const storedDetails = localStorage?.getItem("user-id");
  
  const isUserDetailsNull = storedDetails ? false : true;

  const [showLoading, setShowLoading] = useState(true);
  const [countdown, setCountdown] = useState(3);
  const progress = (5 - countdown) * (100 / 5);

  useEffect(() => {
    if (!isUserDetailsNull) {
      const redirectTimeout = setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("user-id");
        localStorage.removeItem("login-complete")
        localStorage.removeItem("exp");
        localStorage.removeItem("warehouse_data");
        localStorage.removeItem("user_access");
        window.location.href = "/";
      }, 3000);

      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearTimeout(redirectTimeout);
        clearInterval(countdownInterval);
      };
    } else {
      
      const redirectTimeout = setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("login-complete")
        localStorage.removeItem("user-id");
        localStorage.removeItem("exp");
        localStorage.removeItem("warehouse_data");
        localStorage.removeItem("user_access");
        window.location.href = "/";
      }, 1000);
      return () => {
        clearTimeout(redirectTimeout);
      };
    }
  }, []);

  useEffect(() => {
    if (!isUserDetailsNull) {
      setShowLoading(false);
    }
  }, []);

  const animatedBackground = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100,
    backgroundSize: "cover",
    backgroundImage: "linear-gradient(to top, #ACE1AF, #FFFFFF)",
    animation: "animateBackground 4s ease-in-out infinite",
  };

  const containerStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
    zIndex: 9999,
  };

  return  (
    
    <Box style={containerStyle}>
      <ErrorOutlineOutlined sx={{ fontSize: 100, color: "red" }} />
      <Typography variant="h4" color="textPrimary" mt={2}>
        Page Not Found
      </Typography>
      <Typography variant="body1" color="textSecondary" mt={1}>
        The requested page could not be found.
      </Typography>
      <Typography variant="body2" color="textSecondary" mt={2}>
        Redirecting you back in {countdown} seconds...
      </Typography>
      <ClipLoader
        size={30}
        color="#2c7B55"
        variant="determinate"
        value={progress}
        sx={{ marginTop: "10px" }}
      /> 
    </Box>
  );
};
export  {PageNotFound ,UnknownPage};
