import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  useTheme,
  IconButton,
} from "@mui/material";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { tokens } from "../WMStheme";

const SuccessDialog = ({
  open,
  onClose,
  title,
  content,
  okayText,
  handleOkayClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ position: "relative", padding: "16px" }}>
        <Typography variant="h4" color={colors.green[500]}>
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: colors.grey[500],
          }}
        >
          <CloseSharpIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <CheckCircleOutlineIcon
          style={{ fontSize: "48px", color: colors.green[500], marginBottom: "16px" }}
        />
        <Typography
          variant="h6"
          align="center"
          sx={{ whiteSpace: "pre-line",color:"black",
            fontWeight:"bold" }}
        >
          {content}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleOkayClick}
          variant="contained"
          sx={{ backgroundColor: colors.green[500] }}
        >
          <Typography variant="button" color={colors.white.DEFAULT}>
            {okayText}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SuccessDialogBox = ({
  open,
  onClose,
  title,
  content,
  okayText,
  handleOkayClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog open={open} onClose={onClose} sx={{ maxWidth: "100%", maxHeight: "100%" }}>
      <DialogTitle sx={{ position: "relative", width: "600px", padding: "16px" }}>
        <Typography variant="h4" color={colors.green[500]}>
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: colors.grey[500],
          }}
        >
          <CloseSharpIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <CheckCircleOutlineIcon
          style={{ fontSize: "48px", color: colors.green[500], marginBottom: "16px" }}
        />
        <Typography
          variant="h6"
          color={colors.black[800]}
          fontWeight="bold"
          align="center"
          sx={{ whiteSpace: "pre-line" }}
        >
          {content}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleOkayClick}
          variant="contained"
          sx={{ backgroundColor: colors.green[500] }}
        >
          <Typography variant="button" color={colors.white.DEFAULT}>
            {okayText}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { SuccessDialog, SuccessDialogBox };
