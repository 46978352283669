import { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead, Checkbox} from "@mui/material";
import { IoMdAddCircleOutline } from "react-icons/io";
import { ulid } from "ulid";
import * as React from 'react';
import { SuccessDialogBox } from "../PopUpMsg/BulkAllocationPopUp"
import { MdMenuBook } from "react-icons/md"
import { TiTickOutline } from "react-icons/ti";
import { IoIosPerson } from "react-icons/io";
import { GrChapterAdd } from "react-icons/gr";
import SocketReportPopUp from "../../Attendance/HomePage/SocketReportPopup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import dayjs from "dayjs";
import BulkInvoiceDownloadPopUp from "./BulkInvoiceDownloadPopUp";
import BulkAllocationDownloadPopUp from "./BulkAllocationDownloadPopUp";
import axios from "axios";
import NullScreen from "../PopUpMsg/NullScreen";
import SuccessPopUp from "../PopUpMsg/SuccessPopUp";
import ErrorPopUp from "../PopUpMsg/ErrorPopUp";
import { setSoAction } from "../actions/soAction";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../ApiEndpoint/ApiEndpoint";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Form } from "antd";
import { useDispatch } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { ScaleLoader } from "react-spinners";

import {
  Dialog,
  Stack,
  DialogContent,
  useTheme,
  Grid,
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Select,
  Autocomplete,
  FormControl,
  Divider,
  Button,
  ButtonGroup,
  InputAdornment,
  InputLabel,
  ClickAwayListener, Grow, Popper,MenuList,
  DialogTitle,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Label } from "semantic-ui-react";
import { tokens } from "../WMStheme";
import SOStatContainer from "./SOStatContainer";
import MultipleBulkOperation from "./BulkAllocationDownloadPopUp";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

const axiosInstanceOMS = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_OMS_URL}`,
});

// Add an interceptor to include the token in every request
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34519fe6",
    color: theme.palette.common.white,
    padding: "6px 16px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,

    padding: "6px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 2,
  },
}));
const columns = [
  { id: "order_no", label: "Order No." },
  { id: "invoice_no", label: "Invoice No." },
  { id: "wh_name", label: "Warehouse" },
  { id: "customer_name", label: "Customer" },
  { id: "code", label: "Customer Code" },
  { id: "status", label: "Status" },
  { id: "channel", label: "Channel" },
  { id: "date_of_delivery", label: "Delivery Date" },
  { id: "created_at", label: "Punched Time" },
  { id: "updated_at", label: "Last Updated Time" },
];
//to convert epoch to dd/mmy/yyy
const formatDateIST = (epochTimestamp) => {
  // console.log('>>>>>>>>>>epoch: ', epochTimestamp);
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return "";
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} || ${hours}:${minutes}`;
};
//to convert  dd/mmy/yyy to epoch
const formatDate = (epochTimestamp) => {
  const isValidTimestamp =
    !isNaN(epochTimestamp) &&
    epochTimestamp !== null &&
    epochTimestamp !== undefined;
  if (!isValidTimestamp) {
    return "";
  }
  const date = new Date(parseInt(epochTimestamp, 10));
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
const gridContainerStyle = {
  // marginTop:"1%",
  display: "flex",
  height: "66vh",
  width: "100%",
  overflowY: "hidden",
  //  backgroundColor: "red",
};
const statContainerStyle = {
  height: "10vh",
  // backgroundColor: "blue",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const containerStyle8 = {
  maxHeight: "74vh",
  height: "80%",
  display: "flex",
  overflowY: "auto",
  overflowX: "hidden",
  //  backgroundColor: "pink",
};
const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
  width: "100%",
  overflowY: "hidden",
  // backgroundColor: "red",
};
function TableHeadComponent({ handleSelectAllClick, isSelectedAll,isCheckboxEnabled }) {
  return (
    <TableHead>
    <TableRow>
    <TableCell padding="checkbox">
      <Checkbox
        indeterminate={isSelectedAll === "partial"}
        checked={isSelectedAll === "all"}
         onChange={handleSelectAllClick}
      />
    </TableCell>
    {columns.map((column) => (
      <StyledTableCell key={column.id} align="center">
        {column.label}
      </StyledTableCell>
    ))}
  </TableRow>
    </TableHead>
  );
}

function TableRowsComponent({ data1, datasending, status,  setStatus,
  selectedRows,
  handleRowCheckboxClick,isCheckboxEnabled }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState(null);
  const [datatocard, setdatatocard] = useState(null);
  const theme = useTheme();

  const getStatusDisplayText = (backendStatus) => {
    const statusMappings = {
      pending: "Pending",
      confirm: "Confirm",
      allocation: "Allocated",
      shipped: "Shipped",
      return: "Return",
      zoho: "Send to Zoho",

      cancel: "Cancel",
      draft: "Draft",
      // Add more status mappings as needed
    };

    return statusMappings[backendStatus] || backendStatus;
  };
  const StatusTag = ({ status }) => {
    const displayText = getStatusDisplayText(status);

    let tagColor;

    switch (displayText) {
      case "Pending":
        tagColor = "orange";
        break;
      case "Release":
        tagColor = "green";
        break;
      case "Allocated":
        tagColor = "purple";
        break;
      case "Shipped":
        tagColor = "brown";
        break;
      case "Confirm":
        tagColor = "purple";
        break;
      case "Return":
        tagColor = "blue";
        break;
      case "Send to Zoho":
        tagColor = "black";
        break;

      case "Cancel":
        tagColor = "red";
        break;

      default:
        tagColor = "gray";
        break;
    }

    return (
      <Label
        style={{ backgroundColor: tagColor, color: "white" }}
        tag
        size="small"
      >
        {displayText}
      </Label>
    );
  };
  const handleRowClick = ({ row, datasending }) => {
    setSelectedRow(row);
    dispatch(setSoAction("edit", row, row?.so_id));
    navigate("/addso");
  };

 
  
  

  const [apiSuccessOpen, setApiSuccessOpen] = useState(false);
  const handleSuccess = (newState) => {
    setApiSuccessOpen(newState);
  };
  const [apiErrorOpen, setApiErrorOpen] = useState(false);
  const handleFailure = (newState) => {
    setApiErrorOpen(newState);
  };
  const dialogRef = useRef(null);

  return (
    <>
      <TableBody>
        {data1?.map((row, index) => (
          <StyledTableRow
            key={index}
            selected={selectedRows.includes(row?.so_id)} // Highlight row if selected

            onClick={() => handleRowClick({ row, datasending })}
            style={{ cursor: "pointer" }}
          >
          <TableCell padding="checkbox">
          <Checkbox
            checked={selectedRows.includes(row?.so_id)}
            onClick={(event) => event.stopPropagation()} // Stop event propagation
            onChange={() => handleRowCheckboxClick(row?.so_id)}
            // disabled={!isCheckboxEnabled(row?.status)}
          />
        </TableCell>
            {columns?.map((column) => (
              <StyledTableCell key={column?.id} align="center">
                {column?.id === "status" ? (
                  <StatusTag status={row[column?.id]} />
                ) : column?.id === "created_at" ||
                  column?.id === "updated_at" ? (
                  formatDateIST(row[column?.id])
                ) : column?.id === "date_of_delivery" ? (
                  formatDateIST(row[column?.id])
                ) : (
                  row[column?.id]
                )}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </>
  );
}

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const SOMainPage = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  var stored_user_access = JSON.parse(localStorage.getItem("user_access"));
  console.log("user_access retrieved from localStorage: ", stored_user_access);
  const options = ['Download Invoices','Download Report'];
  const bulkSoButtons = ['BULK ALLOCATE', 'BULK SHIPPED', 'BULK ZOHO']
  const userDetail = stored_user_access ? stored_user_access : null;
  const theme = useTheme();
  const dateFormat = "DD/MM/YYYY";
  const navigate = useNavigate();
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 6);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const endDateTime = new Date(currentDate);
  endDateTime.setHours(23, 59, 59, 999);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [startDate, setStartDate] = useState(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState(endDateTime.getTime());
  const [responsedata , setResponseData]= useState("")
  const [soNum, setSoNum] = useState(null);
  const [invoiceNum, setInvoiceNum] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [vendorNames, setVendorNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [soData, setSoData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedSoIds, setSelectedSoIds] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  //const [selectedButton, setSelectedButton] = useState(0);
 const [openBulkInvoiceDialog, setOpenBulkInvoiceDialog] = useState(false);
 const [openBulkAllocationDialog , setOpenBulkAllocationDialog] = useState(false);
  // const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerNames, setCustomerNames] = useState([]);
  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedBulkIndex, setSelectedBulkIndex] = useState(0);
  const [nextBulkStatus,setNextBulkStatus] = useState('allocation');
  const [bulkMenuOpen, setBulkMenuOpen] = useState(false);
  const bulkAnchorRef = useRef(null); 
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState("");


  const handleBulkStatus = async (currStatus, nextStatus) => {
    console.log("Bulk allocation initiated");
    if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
        console.error("Validation failed: No SO IDs selected.");
        setErrorMessage("No Sales Orders selected. Please select and try again!");
        setErrorPopupOpen(true);
        return;
    }
    // Validation: Ensure all selected rows have the correct status
  
  const seletedSoDataStatus = soData?.filter((so) => selectedRows?.includes(so?.so_id));
  const invalidSoData = seletedSoDataStatus.filter(so => so?.status != currStatus);

  if (invalidSoData.length > 0) {
    console.error(`Validation failed: Some Sales Orders are not in ${currStatus} status.`);
    setErrorMessage(`Some Sales Orders are not in the ${currStatus} status. Please review your selection.`);
    setErrorPopupOpen(true);
    return;
  }
    setDialogContent(`Are you sure you want to update status to ${nextStatus} of ${selectedRows.length} sales orders?`);
    setNextBulkStatus(nextStatus);
    setDialogOpen(true);
  }
  const handleBulkClick = async () => {
    if(selectedBulkIndex === 0) {
     await handleBulkStatus('confirm','allocation');
    } else if(selectedBulkIndex === 1) {
    await handleBulkStatus('allocation','shipped');
    } else {
      await handleBulkStatus('shipped','zoho');
    }
  };
   
  const handleBulkItemClick = (event, index) => {
    setSelectedBulkIndex(index);
    setBulkMenuOpen(false);
    // handleBulkClick();
  };
  const proceedWithBulkStatus = async () => {
    setDialogOpen(false);
    try {
      const endpoint = constructEndpoint(selectedRows,nextBulkStatus);

        const payload = { so_ids: selectedRows};


        const response = await updateBulkStatus(endpoint, payload);
        if (response.status === 200) {
            handleSuccessfulResponse(response.data);
            const updatedSoData = soData.map((so) => {
              if (selectedRows.includes(so.so_id)) {
                if(nextBulkStatus === 'allocation') {
                  return { ...so, status: 'Allocated' };
                } else if (nextBulkStatus === 'shipped') {
                  return { ...so, status: 'Shipped' };
                } else {
                  return { ...so, status: 'Send to Zoho' };
                }
              }
              return so;
            });
            setSoData(updatedSoData);
        } else {
            handleErrorResponse("Failed to allocate orders. Please try again.");
        }
    } catch (error) {
      console.log("ERROR >>>>>>>>>", error)
        handleApiError(error);
    }
};
const handleBulkToggle = () => {
  setBulkMenuOpen((prevOpen) => !prevOpen);
  // setOpenBulkInvoiceDialog(!openBulkInvoiceDialog);
};
const handleBulkClose = (event) => {
  if (bulkAnchorRef.current && bulkAnchorRef.current.contains(event.target)) {
    return;
  }
  setBulkMenuOpen(false);
};

const constructEndpoint = (soIds,nextStatus) => {
    const queryString = `so_ids=${encodeURIComponent(soIds.join(","))}&nextStatus=${nextStatus}`;
    return `${API_ENDPOINTS.PUT_WMS_SO_BULK_Status}?${queryString}`;
};

const updateBulkStatus = (endpoint, payload) => {
    return axiosInstance.put(endpoint, payload, {
        headers: { "Content-Type": "application/json" },
    });
};

const handleSuccessfulResponse = (data) => {
    console.log("Allocation results:", data);
    setResponseData(data);
    setSelectedSoIds([]);
    // setTempSkuData([]);
    console.log("State reset after successful allocation.");
    const serverMessage = data.msg;
    setSuccessMessage(serverMessage)
    setSuccessPopupOpen(true);
};


const handleApiError = (error) => {
    const serverMessage = error?.response?.data?.msg|| "Orders Already Allocated" ;
    console.error("API Error:", serverMessage);
    setErrorMessage(serverMessage);
    setErrorPopupOpen(true);
};

const handleErrorResponse = (message) => {
    console.error("Error Response:", message);
    setErrorMessage(message);
    setErrorPopupOpen(true);
};

  


  const fetchCustomerNames = async () => {
    try {
      const response = await axiosInstanceOMS.get(
        API_ENDPOINTS.GET_CUSTOMER_DATA,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      // console.log("data in fetchCustomerNames", data?.data?.data);
      setCustomerNames(data?.data?.data);
    } catch (error) {
      console.log("Error fetching CustomerNames", error);
    }
  };

  const handleClick = () => {
    if (selectedIndex === 0) {
      // Action for "Download Invoices"
      setOpenBulkInvoiceDialog(true);
    } else if (selectedIndex === 1) {
      // Action for "Download Report"
      handleGenerateReportClick();
    }
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    handleClick();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    // setOpenBulkInvoiceDialog(!openBulkInvoiceDialog);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const isCheckboxEnabled = (status) => {
    // Allow checkbox only for 'shipped' and 'zoho'  and 'return' statusesselectedButton
    if ( selectedIndex == 0)
    { return status === "shipped" || status === "zoho" || status === "return" || status === "confirm"; }
    else if( selectedIndex == 1 )
      { return status === "confirm" ; }
  };

  // Handle individual row checkbox click
  const handleRowCheckboxClick = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id) // Remove from array
        : [...prevSelectedRows, id] // Add to array
    );
  };

  // Handle "Select All" checkbox click
const handleSelectAllClick = () => {
  // Filter rows where the checkbox is enabled (i.e., status is 'shipped' or 'zoho')
  const selectableRows = soData?.filter((row) => isCheckboxEnabled(row?.status)).map((row) => row?.so_id);

  if (selectedRows?.length === selectableRows?.length) {
    // If all selectable rows are already selected, deselect all
    setSelectedRows([]);
    setIsSelectedAll(false);
  } else {
    // Select only the selectable rows
    setSelectedRows(selectableRows);
    
    setIsSelectedAll("all");
  }
};
 // Update the select-all checkbox state based on selected rows
useEffect(() => {
  const selectableRows = soData?.filter((row) => isCheckboxEnabled(row?.status)).map((row) => row?.so_id);
  
  if (selectedRows?.length === 0) {
    setIsSelectedAll(false); // No rows selected
  } else if (selectedRows?.length === selectableRows?.length) {
    setIsSelectedAll("all"); // All selectable rows selected
    
  } else {
    setIsSelectedAll("partial"); // Some rows selected
  }
  
  // Log the selectedRows array whenever it changes
  console.log("Selected Rows:", selectedRows);
  // isSelectedAll state 
  console.log("isSelectedAll Data:", isSelectedAll);
}, [selectedRows, soData]);
  const handleAddNew = () => {
    dispatch(setSoAction("add", null, null));

    navigate("/addso");
  };
 
  const statusOptions = [
    { key: "pending", value: "Pending" },
    { key: "confirm", value: "Confirm" },
    { key: "allocation", value: "Allocated" },
    { key: "shipped", value: "Shipped" },
    { key: "return", value: "Return" },
    { key: "zoho", value: "Send to Zoho" },

    { key: "cancel", value: "Cancel" },
    // Add more status options as needed
  ];

  const [refreshCount, setRefreshCount] = useState(1);
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [warehouseId, setWarehouseId] = useState(null);
  const [warehouseData, setWarehouseData] = useState([]);
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [soStats, setSoStats] = useState({});
  const [downloadActive, setDownloadActive] = useState(false);
  const [generatedId, setGeneratedId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogOrders, setOpenDialogOrders] = useState(false);
  const [filterValues, setFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    limit: 12,
    offset: offset,
  });
  useEffect(() => {
    const storedTemporaryFilterValues = localStorage.getItem("SOfilterValue");
    const parsedFilterValues = JSON.parse(storedTemporaryFilterValues);

    if (storedTemporaryFilterValues) {
      console.log("start date ", parsedFilterValues[0]?.start_date);
      console.log("End date ", parsedFilterValues[0]?.end_date);
      console.log("warehouse_id ", parsedFilterValues[0]?.warehouse_id);
      console.log("vendor_id ", parsedFilterValues[0]?.vendor_id);

      const ParsedFilterValues = {
        start_date:
          parsedFilterValues && parsedFilterValues[0]?.start_date
            ? parsedFilterValues[0]?.start_date
            : startDate,

        end_date:
          parsedFilterValues && parsedFilterValues[0]?.end_date
            ? parsedFilterValues[0]?.end_date
            : endDate,

        warehouse_id:
          parsedFilterValues && parsedFilterValues[0]?.warehouse_id
            ? parsedFilterValues[0]?.warehouse_id
            : warehouseId,
        customer_id:
          parsedFilterValues && parsedFilterValues[0]?.customer_id
            ? parsedFilterValues[0]?.customer_id
            : customerId,

        int_so_no:
          parsedFilterValues && parsedFilterValues[0]?.int_so_no
            ? parsedFilterValues[0]?.int_so_no
            : soNum,
        invoice_no:
          parsedFilterValues && parsedFilterValues[0]?.invoice_no
            ? parsedFilterValues[0]?.invoice_no
            : invoiceNum,
        status:
          parsedFilterValues && parsedFilterValues[0]?.status
            ? parsedFilterValues[0]?.status
            : status,

        limit: 12,
        offset:
          parsedFilterValues && parsedFilterValues[0]?.offset
            ? parsedFilterValues[0]?.offset
            : offset,
      };
      console.log("parsedValues----------", parsedFilterValues);
      setStartDate(parsedFilterValues[0]?.start_date);
      setEndDate(parsedFilterValues[0]?.end_date);

      setWarehouseId(parsedFilterValues[0]?.warehouse_id);
      setCustomerId(parsedFilterValues[0]?.customer_id);
      setSoNum(parsedFilterValues[0]?.int_so_no);
      setStatus(parsedFilterValues[0]?.status);
      setOffSet(parsedFilterValues[0]?.offset);
      setInvoiceNum(parsedFilterValues[0]?.invoice_no);
      setFilterValues(ParsedFilterValues);
      setRefreshCount((prevCount) => prevCount + 1);
      console.log("exist in loclastorage ");
    } else {
      console.log("not exist in loclastorage ");
      // If no stored values are found, set the default values
      const defaultTemporaryFilterValues = {
        start_date: oneWeekAgo.getTime(),
        end_date: endDateTime.getTime(),
        limit: 12,
        offset: offset,
      };
      setFilterValues(defaultTemporaryFilterValues);
    }
  }, []);

  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    start_date: oneWeekAgo.getTime(),
    end_date: endDateTime.getTime(),
    sku_id: null,
    int_so_no: null,
    invoice_no: null,
    warehouse_id: null,
    customer_id: null,
    status: status,
  });
  useEffect(() => {
    const storedWarehouseData = localStorage?.getItem("warehouse_data");

    if (storedWarehouseData) {
      // Parse the stored data from string to object
      const parsedWarehouseData = JSON.parse(storedWarehouseData);

      // Set the warehouse data in state
      setWarehouseData(parsedWarehouseData);
    }
  }, [warehouseId]); // The empty dependency array ensures that this effect runs only once on component mount

  useEffect(() => {
    const updatedFilterValues = {
      start_date: startDate,
      end_date: endDate,
      warehouse_id: warehouseId,
      // sku_id: vendorId,
      customer_id: customerId,
      int_so_no: soNum,
      invoice_no: invoiceNum,
      status: status,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [startDate, endDate, status, soNum, warehouseId, customerId, invoiceNum]);
  useEffect(() => {
    fetchVendorNames();
    fetchCustomerNames();
  }, []);

  useEffect(() => {
    fetchSOData(filterValues);
  }, [filterValues, refreshCount]);
  const handleDateChange = (dates, dateStrings) => {
    const startDateTime = moment(dateStrings[0], "DD-MM-YYYY").valueOf();
    const endDateTime = moment(dateStrings[1], "DD-MM-YYYY").endOf("day");
    const endOfDay = endDateTime.valueOf();
    setStartDate(startDateTime);
    setEndDate(endOfDay);
  };

  const fetchVendorNames = async () => {
    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_VENDOR_DATA,
        {
          params: {
            limit: 1000,
            offset: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data?.data?.vendor_list;
      setVendorNames(data); // Remove the square brackets
    } catch (error) {
      console.log("Error fetching vendor data:", error);
    }
  };
  const handleApplyClick = () => {
    const startDateObj = new Date(temporaryFilterValues.start_date);
    const endDateObj = new Date(temporaryFilterValues.end_date);
    
    // Calculate the difference in days
    const differenceDays = Math.abs((endDateObj.getTime() - startDateObj.getTime()) / (1000 * 60 * 60 * 24));
    
    // Check if the difference is greater than a week
    const isDifferenceGreaterThanAWeek = differenceDays < 15;
    console.log("difference-----.",isDifferenceGreaterThanAWeek);
    // Set downloadActive based on the condition
    setDownloadActive(!isDifferenceGreaterThanAWeek);
    
    const newFilterValues = {
      start_date: temporaryFilterValues.start_date,
      end_date: temporaryFilterValues.end_date,
      warehouse_id: temporaryFilterValues.warehouse_id,
      customer_id: temporaryFilterValues.customer_id,
      // vendor_id: temporaryFilterValues.vendor_id,
  
      int_so_no: temporaryFilterValues.int_so_no,
      invoice_no: temporaryFilterValues.invoice_no,
      status: temporaryFilterValues.status,
      limit: 12,
      offset: 1,
    };
    console.log("int po no ", temporaryFilterValues.int_so_no);
    setStartDate(temporaryFilterValues.start_date);
    setEndDate(temporaryFilterValues.end_date);
    setWarehouseId(temporaryFilterValues.warehouse_id);
    setCustomerId(temporaryFilterValues.customer_id);
    setOffSet(1);
  
    // setVendorId(temporaryFilterValues.vendor_id);
    setSoNum(temporaryFilterValues.int_so_no);
    setInvoiceNum(temporaryFilterValues.invoice_no);
    setStatus(temporaryFilterValues.status);
    setFilterValues(newFilterValues);
    localStorage.setItem("SOfilterValue", JSON.stringify([newFilterValues]));
    fetchSOData(newFilterValues);
  };
 
  const onPageChange = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    localStorage.setItem("SOfilterValue", JSON.stringify([updatedValues]));
    fetchSOData(updatedValues);
  };
  const handleGenerateReportClick = async () => {
    setIsLoading(true);
    const requestId = ulid();
    console.log("FilterValues:", filterValues);

    // Clone the original filterValues object

    // Clone the original filterValues object
    const updatedFilterValues = { ...filterValues };

    // Iterate over each key in the filterValues object
    for (const key in updatedFilterValues) {
      // Check if the value corresponding to the key is a string and is an empty string
      if (
        typeof updatedFilterValues[key] === "string" &&
        updatedFilterValues[key].trim() === " "
      ) {
        // Set the value to null
        updatedFilterValues[key] = null;
      }
    }

    // Log the updated filterValues
    console.log("updated filtervalues------->", updatedFilterValues);

    try {
      const response = await axiosInstance.get(
        API_ENDPOINTS.GET_WMS_SO_DOWNLOAD_ORDERS,
        {
          params: {
            ...updatedFilterValues,

            request_id: requestId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        setGeneratedId(requestId);
        setOpenDialog(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };
  const handleRefreshClick = () => {
    const initialValues = {
      start_date: oneWeekAgo.getTime(),
      end_date: endDateTime.getTime(),
      limit: 12,
      offset: 1,
    };
    setOffSet(1);
    setDownloadActive(true);
    // setVendorId(null);
    setSoNum("");
    setInvoiceNum("");
    setWarehouseId(null);
    setStatus(null);
    setCustomerId(null);
    setStartDate(oneWeekAgo.getTime());
    setEndDate(endDateTime.getTime());
    setRefreshCount((prevVal) => prevVal + 1);
    setFilterValues(initialValues);
   
    localStorage.removeItem("SOfilterValue");
    //  fetchSOData(initialValues);
  };
  const fetchSOData = async (newParams) => {
    setIsLoading(true);
    const updatedFilterValues = { ...newParams };

    // Iterate over each key in the filterValues object
    for (const key in updatedFilterValues) {
      // Check if the value corresponding to the key is a string and is an empty string
      if (
        typeof updatedFilterValues[key] === "string" &&
        updatedFilterValues[key].trim() === ""
      ) {
        // Set the value to null
        updatedFilterValues[key] = null;
      }
    }

    // Log the updated filterValues
    console.log("updated filtervalues in fetch data ------->", updatedFilterValues);
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_WMS_SO_LIST, {
        params: updatedFilterValues,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoading(false);
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.Data;
        console.log("data", data);
        setSoData(data?.so_list);
        setSoStats(data?.so_stats);
        setTotalPages(data?.total_pages);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsLoading(false);
      setErrorPopupOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <Typography variant="h1" color={colors.green.DEFAULT}>
            Orders
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "5vh"}}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={1.7} md={1.7}>
                {/* <InputLabel>Delivery Date</InputLabel> */}

                <RangePicker
                  onChange={handleDateChange}
                  key={refreshCount}
                  format={dateFormat}
                  defaultValue={[
                    dayjs(formatDate(startDate), dateFormat),
                    dayjs(formatDate(endDate), dateFormat),
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={1} md={1}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    displayEmpty
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Status" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <IoIosPerson />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {statusOptions?.map((option) => (
                      <MenuItem key={option?.key} value={option?.key}>
                        {option?.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1} md={1}>
                <FormControl fullWidth>
                  <InputLabel>Select Warehouse</InputLabel>
                  <Select
                    label="Select Warehouse"
                    value={warehouseId}
                    onChange={(e) => {
                      setWarehouseId(e.target.value);
                    }}
                    displayEmpty
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "150px" }, // Set the max-height for the menu
                      },
                    }}
                    inputProps={{ "aria-label": "Select Warehouse" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" size="small" disabled>
                          <IoIosPerson />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    {Array.isArray(warehouseData) &&
                      warehouseData?.map((day) => (
                        <MenuItem key={day?.id} value={day?.id}>
                          {day?.wh_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1} md={1}>
                <Autocomplete
                  id="combo-box-customer"
                  options={customerNames || []}
                  getOptionLabel={(option) =>
                    capitalizeFirstLetter(option?.customer_name)
                  }
                  value={
                    customerNames?.find(
                      (customer) => customer?.customer_id === customerId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCustomerId(newValue ? newValue?.customer_id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Customer"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" size="small" disabled>
                              <IoIosPerson />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={1.1} md={1.1}>
                <TextField
                  label="Order No."
                  size="small"
                  fullWidth
                  value={soNum}
                  onChange={(e) => {
                    setSoNum(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PermIdentityIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={1} md={1}>
                <TextField
                  label="Invoice No."
                  size="small"
                  fullWidth
                  value={invoiceNum}
                  onChange={(e) => {
                    setInvoiceNum(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PermIdentityIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={0.7} md={0.7}>
                <Button
                  variant="contained"
                  startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  size="small"
                  onClick={handleApplyClick}
                  disabled={!startDate || !endDate}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12} sm={0.7} md={0.7}>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                  size="small"
                  sx={{
                    backgroundColor: "#34519fe6",
                    color: "#ffffff",
                  }}
                  onClick={handleRefreshClick}
                >
                  Reset
                </Button>
              </Grid>
            
            
              <Grid item xs={12} sm={1.1} md={1.1} >
              <Button
                variant="contained"
                startIcon={
                  <GrChapterAdd
                    style={{ color: colors.white.DEFAULT, fontSize: "22px" }}
                  />
                }
                sx={{
                  borderColor: colors.green.DEFAULT,
                  backgroundColor: colors.green.DEFAULT,
                }}
                onClick={handleAddNew}
                title="Add New"
                disabled={!userDetail?.b_orders_create_order}
              >
                <Typography variant="h7" color={colors.white.DEFAULT}>
                  Create New
                </Typography>
              </Button>
            </Grid>
             {/* Download invoices and download report buttons */}
                <Grid item xs={12} sm={1.4} md={1.4} >
            
                    <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                      <Button
                        startIcon={<FileDownloadIcon style={{ color: "white", fontSize:20 }} />}
                        sx={{
                          backgroundColor: "#34519fe6",
                          color: "#ffffff",
                        }}
                        onClick={handleClick}
                      // disabled={  selectedRows?.status == "Allocated" }
                      >
                        <Typography variant="h7" color="white">
                          {options[selectedIndex]}
                        </Typography>
                      </Button>
                      <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        //  disabled={  !selectedRows?.selectedRows?.length > 0}
                        sx={{
                                  backgroundColor: "#34519fe6",
                                  color: "#ffffff",
                                }}
                      >
                        <ArrowDropDownIcon />
                      </Button>
                  </ButtonGroup>
                  <Popper
                    sx={{ zIndex: 1 }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {options.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  selected={index === selectedIndex}
                                  onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
            </Grid>
{/* bulk order buttons */}
<Grid item xs={12} sm={1.1} md={1.1} >
  <div>
   <ButtonGroup variant="contained" ref={bulkAnchorRef} aria-label="split button">
              <Button
                variant="contained"
                startIcon={
                  <IoMdAddCircleOutline
                    style={{ color: colors.white.DEFAULT, fontSize: 22 }}
                  />
                }
                sx={{
                  borderColor: colors.green.DEFAULT,
                  backgroundColor: colors.green.DEFAULT,
                }}
                onClick={handleBulkClick}
                title="Bulk Status of Selected Inventory"
              >
                <Typography variant="h7" color={colors.white.DEFAULT}>
                  {bulkSoButtons[selectedBulkIndex]}
                </Typography>
              </Button>
              <Button
                size="small"
                aria-controls={bulkMenuOpen ? 'split-button-menu' : undefined}
                aria-expanded={bulkMenuOpen ? 'true' : undefined}
                aria-haspopup="menu"
                onClick={handleBulkToggle}
                sx={{
                      backgroundColor: "#34519fe6",
                      color: "#ffffff",
                        }}
              >
            <ArrowDropDownIcon />
          </Button>
    </ButtonGroup>
    <Popper
      sx={{ zIndex: 1 }}
      open={bulkMenuOpen}
      anchorEl={bulkAnchorRef.current}
      role={undefined}
      transition
      disablePortal
      >
  {({ TransitionProps, placement }) => (
  <Grow
    {...TransitionProps}
    style={{
      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
    }}
  >
  <Paper>
    <ClickAwayListener onClickAway={handleBulkClose}>
      <MenuList id="split-button-menu" autoFocusItem>
        {bulkSoButtons.map((bulkSoButton, index) => (
          <MenuItem
            key={bulkSoButton}
            selected={index === selectedBulkIndex}
            onClick={(event) => handleBulkItemClick(event, index)}
          >
            {bulkSoButton}
          </MenuItem>
        ))}
      </MenuList>
    </ClickAwayListener>
  </Paper>
</Grow>
)}
      </Popper>
      
              <SuccessDialogBox
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              title="Confirm Bulk Status"
              content={dialogContent}
              okayText="Proceed"
              handleOkayClick={proceedWithBulkStatus}
      /></div>
            </Grid>
              </Grid>
            </Grid>
          </Grid>
          {openDialog && (
            <SocketReportPopUp
              setOpenDialog={setOpenDialog}
              requestId={generatedId}
            />
          )}
       
        <Grid style={statContainerStyle}>
          <SOStatContainer statData={soStats} />
        </Grid>

        <Grid container spacing={1} style={containerStyle8}>
          {isLoading ? (
            <div style={loaderContainerStyle}>
              <ScaleLoader color="#495057" loading={isLoading} />
            </div>
          ) : soData?.length === 0 ? (
            <NullScreen containerheight="70vh" containerWidth="100%" />
          ) : (
            <>
              <div style={gridContainerStyle}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 200 }} aria-label="customized table">
                    <TableHeadComponent 
                      handleSelectAllClick={handleSelectAllClick}
          isSelectedAll={isSelectedAll}
           isCheckboxEnabled={isCheckboxEnabled} />
                    <TableRowsComponent
                      data1={soData}
                      status={status}
                      setStatus={setStatus}
                       selectedRows={selectedRows}
          handleRowCheckboxClick={handleRowCheckboxClick}
          isCheckboxEnabled={isCheckboxEnabled}
                    />
                  </Table>
                </TableContainer>
              </div>
              <Grid
                container
                justifyContent="flex-end"
                // style={{ marginTop: "5px" }}
              >
                <Pagination
                  count={totalPages}
                  page={offset}
                  defaultPage={
                    JSON.parse(localStorage.getItem("SOfilterValue"))?.[0]
                      ?.offset || offset
                  }
                  size="small"
                  onChange={onPageChange}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      {openBulkAllocationDialog && (
      <BulkAllocationDownloadPopUp
       setOpenBulkAllocationDialog={setOpenBulkAllocationDialog}
          soIds={selectedRows}
           />
      )}
      {openBulkInvoiceDialog && (
        <BulkInvoiceDownloadPopUp
        setOpenBulkInvoiceDialog={setOpenBulkInvoiceDialog}
          soIds={selectedRows}
        />
      )}
      <SuccessPopUp
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        message={successMessage}
      />
      <ErrorPopUp
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        message={errorMessage}
      />
    </>
  );
};

export default SOMainPage;
