import React , {useState ,useEffect, useRef} from "react";
import Typography from "@mui/material/Typography";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { useTheme } from "@mui/material/styles";
import StrollerIcon from '@mui/icons-material/Stroller';
import DescriptionIcon from '@mui/icons-material/Description';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import { tokens } from "../../WMStheme";
import axios from "axios";
import GenerateBarCode from "../GenerateBarCode";
import Barcode from 'react-barcode';
import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';
import { ScaleLoader } from "react-spinners";
import crateimg from '../../../Assets/crate.webp';
import { API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Box,
  Grid,
  FormControl,
  Select,
  InputLabel,
  IconButton,
  TextField,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});

const AddNewCrate = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const barcodeRefs = useRef([]);
  const handleBackButtonClick = () => {
    navigate("/audit");
  };
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
  
      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");
  
        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
      console.log("nvaigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const [loading, setLoading] = useState(false);
  const [barcodesRendered, setBarcodesRendered] = useState(false); 
  const [showBarcodeComponent, setShowBarcodeComponent] = useState(false);
  const [crateformData, setCrateFormData] = useState({
    colour: 'gray',
    type: 'customer',
    size: 'medium',
    location: 'coldstorage 2',
    crate_count: null,
    description: '',
  });
const[barcodeData, setBarCodeData]=useState([]);
const handleChange = (field, value) => {
  setCrateFormData((prevState) => ({
    ...prevState,
    [field]: field === 'crate_count' ? Number(value) : value, // Convert to number for specific fields
  }));
};

  const handleSubmit = async () => {
    console.log('crateformData in handleSubmit:', crateformData);
    setLoading(true);
    try {
      const endpoint = API_ENDPOINTS.POST_WMS_CRATE_MANAGEMENT_AUDIT_ADD_NEW_CRATE;
      const response = await axiosInstance.post(endpoint,crateformData , {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      let barcodesArray;
      if (response.status = 200 ) {
        
      const data = response.data?.data.codes_array;
      barcodesArray = data;
      console.log('Data->:', data);
      setBarCodeData(data);
      setLoading(false);
      console.log('Response:', data?.codes_array);
      //setBarcodesRendered(false);
      }

      //alert('Form submitted successfully!');
      if (barcodesArray.length === 0) {
        alert('No barcodes available to download');
        return;
      }
      setShowBarcodeComponent(true); // Trigger the GenerateBarCode component
  
    } catch (error) {
       console.error('Error:', error);
       setLoading(false);
     // alert('Failed to submit form');
    }
  };
  
  const handleDownload = () => {
    if (barcodeData.length === 0) {
      alert('No barcodes available to download');
      return;
    }
    setShowBarcodeComponent(true); // Trigger the GenerateBarCode component
  };

  const handleCompletion = () => {
    setShowBarcodeComponent(false); // Reset the component after processing
  };
    
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: colors.green.DEFAULT,
      color: colors.green.DEFAULT,
      fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 1,
      // backgroundColor:" #d50000",
    },
  }))
 
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  })) 
    
     
      
  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          Add New Crates
        </Typography>
        <Grid item xs={12} sm={4.4} md={4.4} spacing={2}>
          <Button
            variant="outlined"
            startIcon={
              <ArrowBackIcon
                style={{ color: colors.green.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
              marginLeft: "5px",
            }}
            onClick={handleBackButtonClick}
          >
            <Typography variant="h5" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ marginTop: "1.7%" }}>
        {/* Left Section (40%) */}
        <Grid item xs={12} sm={4}>
          <Box
            p={2}
            bgcolor="#ffff"
            borderRadius="10px"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
          >
          <img
        src={crateimg}
        alt="Crate Management"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          borderRadius: '2px',
        }}
      />
          </Box>
        </Grid>
 {/* Right Section (60%) */}
      <Grid
        item
        xs={12}
        sm={8}
      
      >
        <Box
          p={5}
          bgcolor="#ffff"
          borderRadius="10px"
          maxHeight="100%"
          height="100%"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
          position="relative"
        >
        
         <Grid container spacing={2} sx={{ marginTop: '1%', marginLeft: '0.6%', marginRight: '0.6%' }}>
             {/* Select Type */}
             <Grid item xs={12} sm={4} md={4} sx={{ marginTop: '1.5%' }}>
              <FormControl fullWidth>
                <InputLabel>Select Type</InputLabel>
                <Select
                  label="Select Type"
                  value={crateformData.type}
                  onChange={(e) => handleChange('type', e.target.value)}
                  fullWidth
                  size="small"
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Type' }}
                  startAdornment={
                    <InputAdornment position="start">
                      <MergeTypeIcon />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="" disabled>
                    Select Type
                  </MenuItem>
                  <MenuItem value="customer">Customer</MenuItem>
                  <MenuItem value="vendor">Vendor</MenuItem>
                  <MenuItem value="internal">Internal</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Select Location */}
            <Grid item xs={12} sm={4} md={4} sx={{ marginTop: '1.5%' }}>
              <FormControl fullWidth>
                <InputLabel>Select Location</InputLabel>
                <Select
                  label="Select Location"
                  value={crateformData?.location}
                  onChange={(e) => handleChange('location', e.target.value)}
                  fullWidth
                  size="small"
                  displayEmpty
                  inputProps={{ 'aria-label': 'Type' }}
                  startAdornment={
                    <InputAdornment position="start">
                      <AddLocationIcon />
                    </InputAdornment>
                  }
                >
                        <MenuItem value="coldstorage 1">ColdStorage 1</MenuItem>
                        <MenuItem value="coldstorage 2">ColdStorage 2</MenuItem>
                        <MenuItem value="coldstorage 3">ColdStorage 3</MenuItem>
                        <MenuItem value="coldstorage 4">ColdStorage 4</MenuItem>
                        <MenuItem value="coldstorage 5">ColdStorage 5</MenuItem>
                  

                </Select>
              </FormControl>
            </Grid>
  
            {/* Select Color */}
            <Grid item xs={12} sm={4} md={4} sx={{ marginTop: '1.5%' }}>
              <FormControl fullWidth>
                <InputLabel>Select Color</InputLabel>
                <Select
                  label="Select Color"
                  value={crateformData.colour}
                  onChange={(e) => handleChange('colour', e.target.value)}
                  fullWidth
                  size="small"
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Color' }}
                  startAdornment={
                    <InputAdornment position="start">
                      <ColorLensIcon />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="" disabled>
                    Select Color
                  </MenuItem>
                  <MenuItem value="red">Red</MenuItem>
                  <MenuItem value="blue">Blue</MenuItem>
                  <MenuItem value="gray">Gray</MenuItem>
                  <MenuItem value="black">Black</MenuItem>
                  <MenuItem value="orange">Orange</MenuItem>
                  <MenuItem value="yellow">Yellow</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
         
          </Grid>
  
          <Grid container spacing={2} sx={{ marginTop: '1%', marginLeft: '0.6%', marginRight: '0.6%' }}>
            {/* Size */}
            
            <Grid item xs={12} sm={4} md={4} sx={{marginTop: '16px'}}>
              <FormControl fullWidth>
              <InputLabel>Size</InputLabel>
              <Select
                label="Size"
                name="size"
                value={crateformData.size || "medium"}
                onChange={(e) => handleChange('size', e.target.value)}
                fullWidth
                margin="normal"
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start" size="small" disabled>
                        <AspectRatioIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >

              <MenuItem value="small">Small</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="large">Large</MenuItem>
              </Select>
               </FormControl>
            </Grid>
  
            {/* Description */}
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="Description"
                name="description"
                type="text"
                value={crateformData.description}
                onChange={(e) => handleChange('description', e.target.value)}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start" size="small" disabled>
                        <DescriptionIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
  
            {/* Number of Crates */}
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                label="No. of Crates"
                name="crate_count"
                type="number"
                value={crateformData.crate_count}
                onChange={(e) => handleChange('crate_count', e.target.value)}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start" size="small" disabled>
                        <StrollerIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
            <Box
            position="absolute"
            bottom="16px"
            right="20px"
            display="flex"
            gap={2}
          >
               <Button
              variant="contained"
              sx={{
                borderColor: colors.green.DEFAULT,
                backgroundColor: colors.green.DEFAULT,
              }}
              disabled={
                !(crateformData?.location &&         
                crateformData?.colour &&
                crateformData?.size &&
                crateformData?.type &&
                crateformData?.crate_count
              )
              }
              onClick={handleSubmit}
              
            >
              <Typography variant="h7" color={colors.white.DEFAULT}>
                 Download Barcodes here 
              </Typography>
            </Button> 
           {showBarcodeComponent && (
            <GenerateBarCode barcodeData={barcodeData} onComplete={handleCompletion} />
          )}
          </Box>
         
        </Box>
       
      </Grid>
      </Grid>
 {
  barcodeData?.length > 0 
  && 
  <TableContainer
        component={Paper}
        sx={{ height: 510, overflowY: "auto" }}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: 300,
            "& .MuiTableCell-body": {
              padding: "1px 1px",
            },
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">SNo.</StyledTableCell>
              <StyledTableCell align="center">Barcode</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {barcodeData?.map((code, index) => (
              <StyledTableRow
                key={index}
                style={{ backgroundColor:"white" }}
              >
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">
                  {code}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
}
    </>
  );
};

export default AddNewCrate;
