// apiEndpoints.js

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_OMS_URL = process.env.REACT_APP_BASE_OMS_URL;

export const API_ENDPOINTS = {
  //Inventory API ENDPOINT
  // DONE
  GET_CROPMANAGEMENT_MAINPAGE: "/general/inventory/dashboard",

  GET_ALL_FARM_NAMES: "/general/all-farm-name",

  // DONE
  GET_CROPMANAGEMENT_CONFIG_FARMNAME: "/general/farmname",

  // DONE
  GET_CROPMANAGEMENT_CONFIG_CROPNAME: "/general/cropname",
  GET_STATIC_DASHBOARD_WEATHER: "general/config/farm",

  // DONE

  GET_CROPMANAGEMENT_CONFIG_LIVEINVENTORY: (farm_id, limit, offset) =>
    `/general/liveinventory?farm_id=${encodeURIComponent(
      farm_id
    )}&limit=${encodeURIComponent(limit)}&page=${encodeURIComponent(offset)}`,

  // DONE
  // GET_CROPMANAGEMENT_HARVESTING: "/general/harvest?farm_name=",
  GET_CROPMANAGEMENT_HARVESTING: (farm_id, limit, offset) =>
    `/general/harvest?farm_id=${encodeURIComponent(
      farm_id
    )}&limit=${encodeURIComponent(limit)}&page=${encodeURIComponent(offset)}`,

  // DONE
  // GET_CROPMANAGEMENT_DAMAGE: "/general/dump?farm_name=",
  GET_CROPMANAGEMENT_DAMAGE: (farm_id, limit, offset) =>
    `/general/dump?farm_id=${encodeURIComponent(
      farm_id
    )}&limit=${encodeURIComponent(limit)}&page=${encodeURIComponent(offset)}`,

  // DONE
  POST_CROPMANAGEMENT_CONFIG_ADDINVENTORY: "/general/inventory/add",

  // DONE
  PUT_CROPMANAGEMENT_DAMAGE: "/general/inventory/dump",

  // DONE
  // POST_CROPMANAGEMENT_HARVEST: "/general/inventory/harvest",
  POST_CROPMANAGEMENT_HARVEST: "/general/inventory/v2-harvest", //------------------------------------
  POST_CROPMANAGEMENT_EDIT_GRADES: "/general/inventory/edit-harvest-grade", //------------------------------------

  // DONE
  PUT_CROPMANAGEMENT_TRANSPLANT: "/general/inventory/transplanting",

  // DONE
  PUT_CROPMANAGEMENT_PHASE: "/general/getallphase",
  PUT_CROPMANAGEMENT_LIVE_ROLLBACK: "/inventory/liverollback",
  PUT_CROPMANAGEMENT_DAMAGE_ROLLBACK: "/inventory/dumprollback", // damage rollback
  PUT_CROPMANAGEMENT_HARVEST_ROLLBACK: "/inventory/harvestrollback", //harvest rollback

  // ---------------------CONFIGURATION MODULE-------------------

  // ---------------------CROP-------------------

  //DONE
  POST_CONFIGURATION_CROP: "/general/config/addcropandvariety",

  //DONE
  POST_CONFIGURATION_ADDNEWCROPVARIETY: "/general/config/addcropvariety",

  //DONE
  GET_CONFIGURATION_CROP: "/general/config/crop",

  //DONE
  GET_CONFIGURATION_CROPTYPE: "/general/dropdown?dropdown=CropType",

  //DONE
  GET_CONFIGURATION_CROP_VARIETY_TYPE:
    "/general/dropdown?dropdown=Crop Variety Type",

  //DONE
  GET_CONFIGURATION_STAGENAME: "/general/dropdown?dropdown=StagesName",

  //DONE
  GET_CONFIGURATION_GRADESNAME: "/general/dropdown?dropdown=GradesName",

  //DONE
  PUT_CONFIGURATION_EDITCROPVARIETY: (id) =>
    `/general/config/cropvariety/${id}`,

  //DONE
  PUT_CONFIGURATION_EDITCROP: `/general/config/edit-crop`,

  // ---------------------FARM-------------------

  //DONE
  GET_CONFIGURATION_FARMTYPE: "/general/dropdown?dropdown=FarmType",
  //DONE
  GET_CONFIGURATION_GROWINGMEDIA: "/general/dropdown?dropdown=Growing Media",
  //DONE
  GET_CONFIGURATION_CAPACITYUNIT: "/general/dropdown?dropdown=Capacity Unit",
  //DONE
  GET_CONFIGURATION_FARM: `/general/config/farmzone`,
  //DONE
  POST_CONFIGURATION_FARM: `/general/config/add-farmzone`,
  //DONE
  PUT_CONFIGURATION_ZONE: `/general/config/edit-farmzone`,
  //DONE
  PUT_CONFIGURATION_FARM: `/general/config/edit-farm`,

  // ---------------------FARMER-------------------
  // DONE
  GET_CONFIGURATION_FARMER: `/general/config/farmer`,
  // DONE
  PUT_CONFIGURATION_FARMER: (id) => `/general/config/editfarmer/${id}`,
  // DONE
  DELETE_CONFIGURATION_FARMER: "/configration/farmer?id=",
  // DONE
  POST_CONFIGURATION_FARMER: "/general/config/add-farmer",

  // ---------------------AUTHENTICATION MODULE------------------

  // ---------------------login-------------------
  POST_AUTHENTICATION_LOGIN: "/auth/login",
  GET_AUTHENTICATION_LOGIN: "/auth/verify",
  GET_AUTHENTICATION_USERDETAILS: "/auth/accessibility?username=",
  // ---------------------reset password-------------------
  POST_AUTHENTICATION_RESETPASSWORD: "/auth/resetpass",
  // ---------------------forgotpassword-------------------
  POST_AUTHENTICATION_FORGOTPASSWORD: "/auth/forgetpassword",

  // ---------------------TASK MANAGEMENT MODULE-------------------
  // DONE
  GET_TASK_MANAGEMENT_TASKDATA: "/taskschedule/gettask",
  // DONE
  POST_TASK_MANAGEMENT_CREATETASK: "/taskschedule/create",
  // DONE
  GET_TASK_MANAGEMENT_TASKLABEL: "/general/dropdown?dropdown=TaskLabel",
  // DONE
  GET_TASK_MANAGEMENT_TASKSTATUS: "/general/dropdown?dropdown=TaskStatus",
  // DONE
  GET_TASK_MANAGEMENT_CROPDATA: (limit, offset) =>
    `/inventory/getalllivecrop?limit=${encodeURIComponent(
      limit
    )}&page=${encodeURIComponent(offset)}`,
  // DONE
  GET_TASK_MANAGEMENT_ZONE_CROP: (farm_id, farm_zone_id) =>
    `/general/getzonecrop?farm_id=${encodeURIComponent(
      farm_id
    )}&farm_zone_id=${encodeURIComponent(farm_zone_id)}`,
  // DONE
  DELETE_TASK_MANAGEMENT_TASK: "/taskschedule/deletetask?task_id=",
  // DONE
  PUT_TASK_MANAGEMENT_TASK: "/taskschedule/updatetask",

  //STATIC DASHBOARD MODULE
  // DONE
  POST_STATIC_DASHBOARD_CONTROL: (device_name) =>
    `/general/actuators/$(device_name)`,
  GET_STATIC_DASHBOARD_EC_PH_TELEMETARY_GRAPH: "/recipe/v1/sensorgraph",
  //CONFIG DASHBOARD MODUL
  // GET_CONFIG_DASHBOARD_WIDGETS:'/configdashboard/getwidget',
  // DELETE_CONFIG_DASHBOARD_WIDGETS:'/configdashboard/deletewidget?id=',
  // POST_CONFIG_DASHBOARD_WIDGETS:'/configdashboard/addwidget',

  //REPORTS MODULE
  // DONE
  GET_REPORT_MODULE_SELECT_OPTIONS: "/report/fields?options=",
  // DONE
  POST_REPORT_MODULE_SELECT_COLUMNDATA: "/report/data",
 

  //COST MANAGEMENT MODULE

  PUT_COST_MANAGEMENT: (id) => `/revenue/update-ledger-entry/${id}`,

  //COST MANAGEMENT MODULE

  // GET_COST_MANAGEMENT_ALL_DATA: `/revenue/get-ledger-actual`,
  // PUT_COST_MANAGEMENT_ACTUAL_DATA: `/revenue/update-actual-ledger`,
  GET_COST_MANAGEMENT_ALL_DATA: `/revenue/v2-get-ledger-actual`, //------------------------------------
  POST_COST_MANAGEMENT_ACTUAL_DATA: `/revenue/v2-update-actual-ledger`, //------------------------------------

  //USERROLE MODULE
  // DONE
  GET_USER_ROLE_GETROLE: "/auth/getrole",
  // DONE
  GET_USER_ROLE_ALLUSERDATA: "/auth/allusers",
  // DONE
  PUT_USER_ROLE_ACCESS: "/auth/accessibility/update",
  // DONE
  POST_USER_ROLE_NEWUSER: "/auth/adduser",

  //FINANCE MODULE
  POST_FINANCE_PLANNING: "/revenue/addledger",
  // GET_FINANCE_PLANNING: "/revenue/get-entry-ledger",
  GET_FINANCE_PLANNING: "/revenue/v2-get-entry-ledger", //------------------------------------

  //FERTIGATION MODULE
  POST_FERTIGATION_SETPOINTS: "/recipe/v1/fertigation-on",
  GET_FERTIGATION_IOTDATA: "/recipe/v1/getiot",
  GET_FERTIGATION_SETPOINTS: "/recipe/v1/latestsetpoint",

  //OMS APIENDPOINT

  POST_OMS_LOGIN: "/oms/login",

  GET_OMS_PROCUREMENT: (limit, offset) => `/oms/get-v2-mis?limit=
${encodeURIComponent(limit)}&offset=${encodeURIComponent(offset)}`,

  POST_OMS_PROCUREMENT_UPDATE_DATA: (mis_id, e_flag, a_flag) =>
    `/oms/update-v2-mis?mis_id=${encodeURIComponent(
      mis_id
    )}&e_flag=${encodeURIComponent(e_flag)}&a_flag=${encodeURIComponent(
      a_flag
    )}`,

  // OMS ANALYTICS ENDPOINTS:
  GET_CUSTOMER_DATA: "/oms/get-customers-list",
  GET_SKU_DATA: "/oms/get-items",
  GET_ORDER_GRAPH_DATA: "/oms/dashboard-stats",
  GET_WEEK_DAYS: "/oms/week-days",
  GET_ORDER_LIST: "/oms/confirm-orders-list",
  GET_OMS_ORDER_REPORT: "/oms/report",
  GET_OMS_ORDER_DETAILS: "oms/v2/order_items",
  // ATTENDANCE ENDPOINTS:
  GET_ATTENDANCE_EMPLOYEE_DATA: "/attendance/get-attendance",
  GET_ATTENDANCE_USER_DATA: "/attendance/get-users",
  GET_ATTENDANCE_REPORT: "/attendance/get-attendance-report",

  //WMS ENDPOINTS
  // LOGIN MODULE
  POST_WMS_LOGIN: "/wms/user/login",

  //USER MODULE
  GET_WMS_USER_LIST: "/wms/user/user-list",
  GET_WMS_USER_ACESS_DATA:(id) =>`wms/user/user-access?id=${encodeURIComponent(id)}`,
  PUT_WMS_USER_ACESS_UPDATE_DATA:(id) =>"wms/user/edit-user-access/" +id,
  //MASTER MODULE
  //WAREHOUSE MASTER
  GET_WMS_WAREHOUSE_DATA: "/wms/warehouse/get-warehouse-list",
  POST_WMS_ADD_WAREHOUSE_DATA: "/wms/warehouse/add",
  PUT_WMS_EDIT_WAREHOUSE_DATA: (id) => "/wms/warehouse/edit-warehouse/" + id,

  // SKU MASTER
  GET_WMS_SKU_DATA: "/wms/sku/get-sku-list",
  POST_WMS_ADD_SKU_DATA: "/wms/sku/add",
  PUT_WMS_EDIT_SKU_DATA: (id) => "/wms/sku/edit-sku/" + id,

  //Family master
  GET_WMS_FAMILY_DATA: "/wms/family/get-family-list",

  //Transport Master
  GET_WMS_TRANSPORT_DATA: "/wms/transporter/get-transporter-list",
  POST_WMS_ADD_TRANSPORT_DATA: "/wms/transporter/add",
  PUT_WMS_EDIT_TRANSPORT_DATA: (id) =>
    "/wms/transporter/edit-transporter/" + id,

  //customer Master
  GET_WMS_CUSTOMER_DATA: "/wms/customer/get-customer-list",
  POST_WMS_ADD_CUSTOMER_DATA: "/wms/customer/add",
  PUT_WMS_EDIT_CUSTOMER_DATA: (id) => "/wms/customer/edit-customer/" + id,
  GET_WMS_DETAILS_OF_SKU: (id) =>
    `/wms/sku/get-details?sku_id=${encodeURIComponent(id)}`,
  PUT_WMS_EDIT_PRODUCT_IN_CUSTOMER_DATA: (id) =>
    "/wms/customer/edit-customer-products/" + id,
  GET_WMS_CUSTOMER_DETAILS: (id) => "/wms/customer/get-customer-details/" + id,
  GET_WMS_LIST_OF_PRODUCT_FOR_MAPPING: "/wms/customer/customer-products-all",
  PUT_WMS_MAPPING_PRODUCT_WITH_CUSTOMER: (id) =>
    "/wms/customer/edit-customer-products/" + id,

  //vendor master
  GET_WMS_VENDOR_DATA: "/wms/vendor/get-vendor-list",
  GET_WMS_VENDOR_DETAILS: (id) => "/wms/vendor/get-vendor-details/" + id,
  POST_WMS_ADD_VENDOR_DATA: "/wms/vendor/add",
  PUT_WMS_EDIT_VENDOR_DATA: (id) => "/wms/vendor/edit-vendor/" + id,
  GET_WMS_SKU_MAPPED_WITH_VENDOR: "/wms/vendor/vendor-sku",
  PUT_WMS_EDIT_VENDOR_SKU_MAPPPED: (id) =>
    `/wms/vendor/add-vendor-sku?vendor_id=${encodeURIComponent(id)}`,
  //customer group master

  //product master
  GET_WMS_PRODUCT_MASTER_DATA: "/wms/product/get-product-list",
  POST_WMS_PRODUCT_ADD_DATA: "/wms/product/add",
  PUT_WMS_EDIT_PRODUCT_DATA: (id) => "/wms/product/edit-product/" + id,
  GET_WMS_PRODUCT_SKU_ALL: "/wms/product/product-sku-all",
  GET_WMS_PRODUCT_DETAILS: (id) => "/wms/product/get-product-details/" + id,
  PUT_WMS_EDIT_PRODUCT_ITEMS: (id) => "/wms/product/edit-product-item/" + id,

  //family master

  GET_WMS_FAMILY_DATA: "/wms/family/get-family-list",
  POST_WMS_ADD_FAMILY_DATA: "/wms/family/add",
  GET_WMS_FAMILY_DETAILS_DATA: (id) => "/wms/family/get-family-details/" + id,
  PUT_WMS_FAMILY_DATA: (id) => "/wms/family/edit-family/" + id,

  //customerGroupMaster

  GET_WMS_CUSTOMERGROUP_DATA: "/wms/group/get-group-list",
  POST_WMS_ADD_CUSTOMERGROUP_DATA: "/wms/group/add",
  GET_WMS_CUSTOMER_GROUP_CUSTOMER_LIST: "/wms/group/get-customers",
  PUT_WMS_CUSTOMERGROUP_DATA: (id) => "/wms/group/edit-group/" + id,
  PUT_WMS_CUSTOMER_GROUP_EDIT_CUSTOMER_MAPPED: (id) =>
    "/wms/group/edit-customer/" + id,
  // master download 
  GET_WMS_DOWNLOAD_MASTER:"/wms/admin/download-master",

  //INVENTORY MODULE
  GET_WMS_INVENTORY_DATA: "/wms/customer/get-customer-list",
  GET_DOWNLOAD_PURCHASE_SHEET: "/wms/report/download-purchase-sheet",

  //STO MODULE
  GET_WMS_STO_LIST_OF_ALL_STO: "/wms/sto/get-list",
  GET_WMS_STO_DETAIL_OF_STO : (id , channel) => `/wms/sto/details?sto_id=${encodeURIComponent(id)}&channel=${encodeURIComponent(channel)}`,
  POST_WMS_STO_ADD_NEW: (from_wh_id, to_wh_id ,status ,channel) =>
    `/wms/sto/add?from_wh_id=${encodeURIComponent(
      from_wh_id
    )}&to_wh_id=${encodeURIComponent(to_wh_id)}&status=${encodeURIComponent(status)}&channel=${encodeURIComponent(channel)}`,

  GET_WMS_STO_TRANSFERABLE_INV: "/wms/sto/inv",

  //po MODULE
  GET_WMS_PO_LIST: "/wms/purchase/po-list",
  GET_WMS_VENDOR_LIST_FILTER: "/wms/purchase/vendor-list-filter",
  POST_WMS_CREATE_PO_ORDER: (status, vendor_id, warehouse_id) =>
    `/wms/purchase/create-po?status=${encodeURIComponent(
      status
    )}&vendor_id=${encodeURIComponent(
      vendor_id
    )}&warehouse_id=${encodeURIComponent(warehouse_id)}`,
  PUT_WMS_EDIT_PO_ORDER: (id) => "/wms/purchase/edit-po/" + id, //status as a param
  GET_WMS_PO_DETAILS: (id) => "/wms/purchase/po-details?po_id=" + id,
  GET_WMS_PO_VENDOR_SKU_LIST: "/wms/purchase/vendor-sku-list",
  GET_WMS_PO_DOWNLOAD_ORDERS:"/wms/report/download-po",

  //so module

  GET_WMS_SO_LIST: "/wms/sales/so-list",
  GET_WMS_CUSTOMER_LIST_FILTER: "/wms/sales/customer-list-filter",
  POST_WMS_CREATE_SO_ORDER: (status, customer_id, warehouse_id) =>
    `/wms/sales/create-so?status=${encodeURIComponent(
      status
    )}&customer_id=${encodeURIComponent(
      customer_id
    )}&warehouse_id=${encodeURIComponent(warehouse_id)}`,
  PUT_WMS_EDIT_SO_ORDER: (id) => "/wms/sales/edit-so/" + id, //status as a param
  GET_WMS_SO_DETAILS: (id) => "/wms/sales/so-details?so_id=" + id,
  GET_WMS_SO_CUSTOMER_SKU_LIST: "/wms/sales/customer-item-list",
  GET_WMS_SO_DOWNLOAD_ORDERS: "/wms/report/download-so",
  GET_WMS_SO_BULK_INVOICES_DOWNLOAD : "/wms/sales/bulk-invoices", 
  PUT_WMS_SO_BULK_Status: "/wms/sales/bulk-order-status",
  //Reports MODULE 
  GET_SALES_GRID_DATA: "/wms/report/salereport",
  GET_PROFITLOSS_GRID_DATA:"/wms/report/pnl",
  GET_DOWNLOAD_SALES_REPORT: "/wms/report/download-sales",
  GET_SALES_REPORT_DATA: "/wms/report/sales-report",

  GET_INVENTORY_GRID_DATA: "/wms/report/damgereport",
  GET_DOWNLOAD_INVENTORY_REPORT: "/wms/report/download-damage",
  GET_INVENTORY_REPORT_DATA: "/wms/report/inventory-report",
  GET_INVENTORY_ADJUSTMENT_REPORT_DATA : "/wms/report/invadjust-v2",
  GET_BILL_DATA: "/wms/purchase/get-bill-data",
  GET_INVOICE_DATA: "/wms/sales/invoice-details",
  GET_WMS_REPORTS_MODULE_PURCHASE_REPORT : "/wms/report/purchase-report",
GET_WMS_REPORTS_MODULE_PURCHASE_REPORT_DOWNLOAD: "/wms/report/purchase-download",
GET_WMS_REPORT_MODULE_SALES_REPORT: "/wms/report/salereport",
GET_WMS_REPORT_MODULE_DOWNLOAD_SALES_REPORT:"/wms/report/download-sales-report",
GET_WMS_REPORT_MODULE_PRODUCT_LINKAGE_REPORT:"/wms/report/linkage-report",
GET_WMS_REPORT_MODULE_PRODUCT_LINKAGE_DOWNLOAD_REPORT:"/wms/report/download-linkage-report",
  //STO Module

  //Cycle count Module
  GET_WMS_LIST_OF_PREVIOUS_CYCLE_COUNT: "/wms/cycle-count/get-list",
  GET_WMS_DETAIL_OF_CYCLE_COUNT: (id) =>
    `/wms/cycle-count/details?cycle_count_id=` + id,
  GET_WMS_ADD_CYCLE_COUNT: (id) => "/wms/cycle-count/add?warehouse_id=" + id,
  GET_WMS_INVENTORY_FOR_CYCLE_COUNT: "/wms/cycle-count/inv",
  GET_WMS_INVENTORY_ITEM_WISE_FOR_CYCLE_COUNT : "/wms/cycle-count/v2-inv",
  GET_DOWNLOAD_CYCLE_COUNT: "/wms/cycle-count/download",

  //INVENTORY MODULE
  GET_WMS_INVENTORY_DATA: "/wms/inventory/data",

  //Cycle count Module
  // GET_CYCLE_COUNT_LIST:"";

  // INTEGRATION_MODULE
  GET_WMS_ZOHO_LOGS: "/wms/admin/get-zoho-logs",
  POST_REPUSH_ZOHO_API: "/wms/admin/repush-zoho",
  

  POST_UPLOAD_PO_S3: (id, type, link) =>
    `/wms/admin/sentmail?id=${encodeURIComponent(
      id
    )}&type=${encodeURIComponent(
      type
    )}&link=${encodeURIComponent(link)}`,

  // REPACK MODULE
  GET_REPACK_PRODUCT_LIST: "/wms/product/product-filter",
  POST_REPACK_ADD_PRODUCT: "/wms/inventory/create-product",



  //dropdwon apis 
  GET_PO_MODULE_POTYPE: "/wms/admin/drop-down?name=POType",
  GET_CONFIGURATION_MODULE_SKU_UOMTYPE: "/wms/admin/drop-down?name=UOM",
  GET_CONFIGURATION_MODULE_VENDOR_PAYMENTMODETYPE: "/wms/admin/drop-down?name=PaymentMode",
  GET_CONFIGURATION_MODULE_CUSTOMER_CUSTOMERTYPE: "/wms/admin/drop-down?name=CustomerType",
  GET_CONFIGURATION_MODULE_VENDOR_VENDORTYPE: "/wms/admin/drop-down?name=VendorType",

  GET_CONFIGURATION_MODULE_VENDOR_TAXZONETYPE: "/wms/admin/drop-down?name=TaxZone",



  
  //USER MODULE (CONFIG DASHBOAD FOR WMS & OMS)
  GET_OMS_USER_LIST: "/oms/all-users",
  POST_OMS_ADD_NEW_USER_LIST: "/oms/signup",
  POST_WMS_ADD_NEW_USER_LIST: "/wms/user/add",
  GET_OMS_CUSTOMER_LINK_WRT_USER: "/wms/user/oms-customer",
  POST_OMS_ADD_NEW_CUSTOMER: (userId) =>
    `/wms/user/add-user-customer?oms_user=${encodeURIComponent(userId)}`,



  //dashboard rate prediction
  GET_WMS_DASHBOARD_RATE_PREDICTION_GET_SKU :"/wms/dashboard/get-diff-sku",
  GET_WMS_DASHBOARD_RATE_PREDICTION_DATA :(skuCode)=>"/wms/dashboard/sku-rate-list?sku_code=" + skuCode,
   
  //CRATE MANAGEMENT MODULE
  POST_WMS_CRATE_MANAGEMENT_AUDIT_ADD_NEW_CRATE : "/wms/crate/create",
  GET_WMS_CRATE_MANAGEMENT_AUDIT_CRATE_DATA:"/wms/crate/get-details",
  POST_WMS_CRATE_MANAGEMENT_OUTBOUND_CUSTOMER: "/wms/crate/outbound-customer",
  POST_WMS_CRATE_MANAGEMENT_OUTBOUND_CUSTOMER_FETCH_DATA: "/wms/crate/outbound-so-fetch",
  POST_WMS_CRATE_MANAGEMENT_INBOUND_CUSTOMER: "/wms/crate/inbound-customer",
  POST_WMS_CRATE_MANAGEMENT_INBOUND_CUSTOMER_FETCH_CNAME : "/wms/crate/inbound-customer-fetch",
  POST_WMS_CRATE_MANAGEMENT_OUTBOUND_VENDOR: "/wms/crate/outbound-vendor",
  POST_WMS_CRATE_MANAGEMENT_OUTBOUND_VENDOR_FETCH_DATA: "/wms/crate/outbound-po-fetch",
  POST_WMS_CRATE_MANAGEMENT_INBOUND_VENDOR: "/wms/crate/inbound-vendor",
  GET_WMS_CRATE_MANAGEMENT_DASHBOARD_GET_DATA: "/wms/crate/dashboard",
}; 
