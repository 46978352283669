import React from 'react'
import Card from "react-bootstrap/Card";
import {
  Dialog,
  DialogTitle,
  Stack,
  DialogContent,
  IconButton,
} from "@mui/material";
import { Typography,useTheme, Divider } from "@mui/material";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import { tokens } from "../../WMStheme";

const CrateDashBoardItem = ({ data }) => {
      const theme = useTheme();
      const colors = tokens(theme.palette.mode);
    
    const {key = "",value=""} = data;
   let title = "";
   switch(key) {
    case "total_fambo_crates" : title = "Fambo Crates";
    break;

    case "crates_for_outbound" : title = "Outbound Crates";
    break;

    case "internal_crates" : title = "Internal Crates";
    break;

    case "damage_crates" : title = "Damaged Crates";
    break;

    case "vendor_crates_in_fambo" : title = "Vendor Crates";
    break;

    case "total_customer_avl_crates" : title = "Available Crates";
    break;

    case "total_crates_with_customer" : title = "Customer Crates";
    break;
    
    case "total_customer_old_updates_7" : title = "Customer Old Updates";
    break;


   }
    return (       
        <div
          className="col-lg-3 col-md-3 col-sm-12 mb-5"
          style={{ marginBottom: "10rem" }}
        >
          <Card
            style={{
              width: "80%",
              height: "120%",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)",
              overflow: "hidden",
              backgroundColor: "#ffffff",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Title
                className="text-center"
                style={{ alignItems: "center" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.8}
                >
                
                  <Typography
                    variant="h5"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "16px" }}
                  >
                    {title}
                  </Typography>
                </Stack>

              
              </Card.Title>
              <div className="d-flex justify-content-center">
              <Typography
                    variant="h6"
                    color={colors.black.DEFAULT}
                    sx={{ fontSize: "14px" }}
                  >
                    {value}
                    </Typography>

              
              </div>

           
            </Card.Body>
          </Card>
        </div>
    );
  };

  export default CrateDashBoardItem;