import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead, Checkbox } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState, useEffect, useCallback } from "react";
import Pagination from "@mui/material/Pagination";
import { TiTickOutline } from "react-icons/ti";
import RefreshIcon from "@mui/icons-material/Refresh";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BASE_WMS_URL, API_ENDPOINTS } from "../../../ApiEndpoint/ApiEndpoint";
import { ScaleLoader } from "react-spinners";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import GenerateBarCode from "../GenerateBarCode";
import { Label } from "semantic-ui-react";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import NullScreen from "../../PopUpMsg/NullScreen";
import AuditBulkQRGenerationProgresPopUp from "../../Reports/AuditBulkQRGenerationProgresPopUp";
import {
  Typography,
  Button,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  Box,
  Input,
  MenuItem,
  InputLabel,
  createTheme,
  ThemeProvider,
  Select,
  FormHelperText,
  FormControl,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { tokens } from "../../WMStheme";
import { MdModeEdit } from "react-icons/md";
import { ulid } from "ulid";


const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_WMS_URL}`,
});
const AuditMainPage = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        const currentTime = Date.now();
        const exp = localStorage.getItem("exp");

        // Check if the token is still valid
        if (currentTime <= exp) {
          console.log("comapring the current time and exp time  ");
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // Token has expired, redirect to login page
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("user-id");
          localStorage.removeItem("exp");
          localStorage.removeItem("warehouse_data");
          console.log("navigate to login page ");
          // Optionally, you can redirect to the login page programmatically
          navigate("/");
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const typography = tokens(theme.palette.mode.typography);
  const navigate = useNavigate();
  const [offset, setOffSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const gridContainerStyle = {
    // marginTop:"1%",
    display: "flex",
    height: "65vh",
    width: "100%",
    overflowY: "hidden",
    //  backgroundColor: "red",
  };

  const containerStyle8 = {
    maxHeight: "70vh",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
  };
  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
    width: "100%",
    overflowY: "hidden",
  };
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const [filterValues, setFilterValues] = useState({
    limit: 100,
    offset: offset,
  });
  const [temporaryFilterValues, setTemporaryFilterValues] = useState({
    limit: 100,
    offset: offset,
    // colour: null,
    // crate_code: null,
    // type: null,
    // size: null,
    // location: null,
  });
 
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
const [colour , setColour] = useState(null);
const [cratecode , setCrateCode] = useState(null);
const [ type , setType] = useState(null);
const[size , setSize] = useState(null);
const[location , setLocation] = useState(null);
const [isSelectedAll, setIsSelectedAll] = useState(false);
const [selectedRows, setSelectedRows] = useState([]);
const [rows, setRows] = useState([]);
const [bulkqr, setBulkQr] = useState(false);
const [openBulkQRDialog ,setOpenBulkQRDialog] = useState(false);
  useEffect(() => {
    fetchCratesData(filterValues);
  }, [filterValues]);
  const fetchCratesData = async (newParams) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_WMS_CRATE_MANAGEMENT_AUDIT_CRATE_DATA}`,
        {
          params: newParams,

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 305) {
        const data = await response?.data?.data;
        console.log("data", data);
        setRows(data?.crate_list);
        setTotalPages(data?.total_pages);

        setIsLoading(false);
        //console.log("customer  Data", data?.customer_list);
      } else {
        console.log("Error fetching details");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      // Set loading to false after the request is completed
    }
  };
  const handleChangePage = (event, newPage) => {
    setOffSet(newPage);
    const updatedValues = { ...filterValues, offset: newPage };
    setFilterValues(updatedValues);
    fetchCratesData(updatedValues);
  };
  const formatDate = (epochTimestamp) => {
    const isValidTimestamp =
      !isNaN(epochTimestamp) &&
      epochTimestamp !== null &&
      epochTimestamp !== undefined;
    if (!isValidTimestamp) {
      return "Invalid Timestamp";
    }
    const date = new Date(parseInt(epochTimestamp, 10));
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    const updatedFilterValues = {
      limit: 100,
    colour: colour,
    crate_code: cratecode,
    type: type,
    size: size,
    location: location,
    status: status,
     offset: offset,
    };
    setTemporaryFilterValues(updatedFilterValues);
  }, [colour, cratecode, type, size,location,status,offset]);
const handleApplyClick =() =>{
  const newFilterValues = {
    limit: 100,
    offset: 1,
    colour: temporaryFilterValues.colour,
    crate_code: temporaryFilterValues.crate_code,
    type: temporaryFilterValues.type,
    size: temporaryFilterValues.size,
    location: temporaryFilterValues.location,
    status: temporaryFilterValues.status,
  };
    setColour(temporaryFilterValues.colour);
    setCrateCode(temporaryFilterValues.crate_code);
    setSize(temporaryFilterValues.size);
    setStatus(temporaryFilterValues.status);
    setType(temporaryFilterValues.type);
    setLocation(temporaryFilterValues.location);
    setOffSet(1);
    setFilterValues(newFilterValues);
    fetchCratesData(newFilterValues);

  

}
const handleRefreshClick = () => {
  const initialValues = {
    
    limit: 100,
    offset: 1,
  };
  setOffSet(1);

  setColour(null);
  setCrateCode("");
  setSize("");
  setStatus(null);
  setType(null);
  setLocation(null);
  setSelectedRows([]);
  setFilterValues(initialValues);
  
  
  //  fetchSOData(initialValues);
};
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#34519fe6",
      color: theme.palette.common.white,
      padding: "6px 16px",
      position: "sticky", // Make header sticky
      top: 0,
      zIndex:10,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,

      padding: "6px 16px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#ffff",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 2,
    },
    
  }));
   // Handle individual row checkbox click
   const handleRowCheckboxClick = (crateCode) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(crateCode)
        ? prevSelectedRows.filter((code) => code !== crateCode) // Remove from array
        : [...prevSelectedRows, crateCode] // Add to array
    );
  };
  
  const handleSelectAllClick = () => {
    const selectableRows = rows?.map((row) => row?.crate_code);
  
    if (selectedRows?.length === selectableRows?.length) {
      // If all selectable rows are already selected, deselect all
      setSelectedRows([]);
      setIsSelectedAll(false);
    } else {
      // Select all crate codes
      setSelectedRows(selectableRows);
      setIsSelectedAll("all");
    }
  };
  
  useEffect(() => {
    const selectableRows = rows?.map((row) => row?.crate_code);

    if (selectedRows?.length === 0) {
      setIsSelectedAll(false); // No rows selected
    } else if (selectedRows?.length === selectableRows?.length) {
      setIsSelectedAll("all"); // All selectable rows selected
      
    } else {
      setIsSelectedAll("partial"); // Some rows selected
    }
    
    // Log the selectedRows array whenever it changes
    console.log("Selected Rows:", selectedRows);
    // isSelectedAll state 
    console.log("isSelectedAll Data:", isSelectedAll);
  }, [selectedRows, rows]);
  const columns = [
    { label: "Status", id: "avl_status" },
    { label: "Type", id: "type" },
    { label: "Crate Code", id: "crate_code" },
    { label: "Color", id: "colour" },
    { label: "Size", id: "size" },
    { label: "Location", id: "location" },
    { label: "Created At", id: "created_at" },
    { label: "Updated At", id: "updated_at" },
  ];
  function TableHeadComponent({
    handleSelectAllClick,
    isSelectedAll,
    isCheckboxEnabled,
  }) {
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
            indeterminate={isSelectedAll === "partial"}
            checked={isSelectedAll === "all"}
           onChange={handleSelectAllClick}
            />
          </TableCell>
          {columns.map((column) => (
            <StyledTableCell key={column.id} align="center">
              {column.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  const StatusTag = ({ status }) => {
    // const isActive = status === "true"; // Convert string to boolean

    let tagColor;
    let statusLabel;

    switch (status) {
      case true:
        tagColor = "#0B823A";
        statusLabel = "Available";
        break;
      case false:
        tagColor = "#DE3721";
        statusLabel = "UnAvailable";
        break;
      default:
        tagColor = "gray";
        statusLabel = "Unknown";
        break;
    }

    return (
      <Label
        style={{
          backgroundColor: tagColor,
          color: "white",
          fontWeight: typography.h1,
          fontFamily: "Lexend Deca",
          cursor: "pointer",
          width: "auto",
        }}
        tag
      >
        {statusLabel}
      </Label>
    );
  };
  const TypeTag = ({ type }) => {
    // const isActive = status === "true"; // Convert string to boolean

    let tagColor;
    let statusLabel;

    switch (type) {
      case "customer":
        tagColor = "#9CA2F1FF";
        statusLabel = "Customer";
        break;
      case "internal":
        tagColor = "#F167A180";
        statusLabel = "Internal";
        break;
      case "vendor":
        tagColor = "#85BB9DFF";
        statusLabel = "Vendor";
        break;
      default:
        tagColor = "gray";
        statusLabel = "Unknown";
        break;
    }

    return (
      <Label
        style={{
          backgroundColor: tagColor,
          color: "white",
          fontWeight: typography.h1,
          fontFamily: "Lexend Deca",
          cursor: "pointer",
          width: "auto",
        }}
        tag
      >
        {statusLabel}
      </Label>
    );
  };
  function TableRowsComponent({
    data1,
    datasending,
    status,
    setStatus,
    selectedRows,
    handleRowCheckboxClick,
    isCheckboxEnabled,
  }) {
    //const dispatch = useDispatch();
    //const navigate = useNavigate();

    const [selectedRow, setSelectedRow] = useState(null);
    const [datatocard, setdatatocard] = useState(null);

    const handleRowClick = ({ row, datasending }) => {
      //setSelectedRow(row);
      //dispatch(setSoAction("edit", row, row?.so_id));
      //navigate("/addso");
    };

    const [apiSuccessOpen, setApiSuccessOpen] = useState(false);

    //const dialogRef = useRef(null);

    return (
      <>
        <TableBody>
          {data1?.map((row, index) => (
            <StyledTableRow
              key={index}
              selected={selectedRows.includes(row?.id)} // Highlight row if selected

              //onClick={() => handleRowClick({ row, datasending })}
              style={{ cursor: "pointer" }}
            >
              <TableCell padding="checkbox">
                <Checkbox
                checked={selectedRows.includes(row?.crate_code)} // Check based on crate_code
                onClick={(event) => event.stopPropagation()} // Stop event propagation
                onChange={() => handleRowCheckboxClick(row?.crate_code)} // Pass crate_code
                  //disabled={!isCheckboxEnabled(row?.status)} // Disable checkbox if not 'shipped' or 'zoho'
                />
              </TableCell>
              {columns?.map((column) => (
                <StyledTableCell key={column?.id} align="center">
                  {column?.id === "avl_status" ? (
                    <StatusTag status={row[column?.id]} />
                  ) : column?.id === "created_at" ||
                    column?.id === "updated_at" ? (
                    formatDate(row[column?.id])
                  ) : column?.id === "type" ? (
                    <TypeTag type={row[column?.id]} />
                  ) : (
                    row[column?.id]
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </>
    );
  }
const handleGenerateBulkQR =()=>{
  setOpenBulkQRDialog(true);
  setBulkQr(true);
  }
   const handleCompletion = () => {
    
    setBulkQr(false); // Reset the component after processing
  };
  const handleBackButtonClick = () => {
    navigate("/crate");
  };

  const handleAddNewCrate = () => {
    navigate("/addnewcrate");
  };

   const handleGenerateReportClick = async () => {
     setIsLoading(true);
     const requestId = ulid();
     try {
       const response = await axiosInstance.get(
         API_ENDPOINTS.GET_WMS_CRATE_MANAGEMENT_AUDIT_CRATE_DATA,
         {
           params: {
             ...filterValues,
             download: 1,
             request_id: requestId,
           },
           headers: {
             "Content-Type": "application/json",
           },
         }
       );
       setIsLoading(false);
       if (response.status >= 200 && response.status < 305) {
         setGeneratedId(requestId);
         setOpenDialog(true);
       }
     } catch (error) {
       setIsLoading(false);
     }
   };
  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center the content
          marginTop: "1%",
        }}
      >
        <Typography variant="h1" color={colors.green.DEFAULT}>
          Audit ( Manage the Crates Details)
        </Typography>
        <Grid item xs={12} sm={7.4} md={7.4} spacing={2}>
          <Button
            variant="outlined"
            startIcon={
              <ArrowBackIcon
                style={{ color: colors.green.DEFAULT, fontSize: 26 }}
              />
            }
            sx={{
              borderColor: colors.green.DEFAULT,
              color: colors.green.DEFAULT,
              marginLeft: "1px",
            }}
            onClick={handleBackButtonClick}
          >
            <Typography variant="h5" color={colors.green.DEFAULT}>
              Back
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "10vh" }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={1.4} md={1.4} sx={{ marginTop: "-1.1%" }}>
              <TextField
                label="Crate Code"
                name="size"
                value={cratecode}
                onChange={(e) => setCrateCode( e.target.value)}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start" size="small" disabled>
                        <AspectRatioIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={1.1} md={1.1}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  fullWidth
                  required
                  size="small"
                  displayEmpty
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  inputProps={{ "aria-label": "Type" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                >
                  <MenuItem value="" disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value={true}>Available</MenuItem>
                  <MenuItem value={false}>Unavailable</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={1.1} md={1.1}>
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  label="Type"
                  fullWidth
                  required
                  size="small"
                  displayEmpty
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  inputProps={{ "aria-label": "Type" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                >
                  <MenuItem value="" disabled>
                    Select Type
                  </MenuItem>
                  <MenuItem value="customer">Customer</MenuItem>
                  <MenuItem value="vendor">Vendor</MenuItem>
                  <MenuItem value="internal">Internal</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={1.1} md={1.1}>
              <FormControl fullWidth>
                <InputLabel>Color</InputLabel>
                <Select
                  label="Color"
                  fullWidth
                  required
                  size="small"
                  displayEmpty
                  value={colour}
                  onChange={(e) => setColour(e.target.value)}
                  inputProps={{ "aria-label": "Type" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                >
                  <MenuItem value="" disabled>
                    Select Color
                  </MenuItem>
                  <MenuItem value="red">Red</MenuItem>
                  <MenuItem value="blue">Blue</MenuItem>
                  <MenuItem value="gray">Gray</MenuItem>
                  <MenuItem value="black">Black</MenuItem>
                  <MenuItem value="orange">Orange</MenuItem>
                  <MenuItem value="yellow">Yellow</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Location */}
            <Grid item xs={12} sm={1.1} md={1.1}>
              <FormControl fullWidth>
                <InputLabel>Location</InputLabel>
                <Select
                  label="Location"
                  fullWidth
                  required
                  size="small"
                  displayEmpty
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  inputProps={{ "aria-label": "Type" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                >
                  <MenuItem value="" disabled>
                    Select Location
                  </MenuItem>
                   <MenuItem value="coldstorage 1">ColdStorage 1</MenuItem>
                    <MenuItem value="coldstorage 2">ColdStorage 2</MenuItem>
                    <MenuItem value="coldstorage 3">ColdStorage 3</MenuItem>
                    <MenuItem value="coldstorage 4">ColdStorage 4</MenuItem>
                    <MenuItem value="coldstorage 5">ColdStorage 5</MenuItem>
                </Select>
              </FormControl>
            </Grid>
             {/*Size*/}
            <Grid item xs={12} sm={1.1} md={1.1}>
            <FormControl fullWidth>
            <InputLabel>Size</InputLabel>
            <Select
                label="Size"
                name="size"
                 value={size}
                onChange={(e) => setSize( e.target.value)}
                fullWidth
                displayEmpty
                size="small"
                margin="normal"
                variant="outlined"
                inputProps={{ "aria-label": "Type" }}
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
              >
                
                <MenuItem value="" disabled>
                    Select Size
                  </MenuItem>
                <MenuItem value="small">Small</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="large">Large</MenuItem>
        
                </Select>
                  </FormControl>
            </Grid>

             {/*Apply Button */}
            <Grid item xs={12} sm={0.7} md={0.7}>
              <Button
                variant="contained"
                startIcon={<TiTickOutline style={{ fontSize: "26px" }} />}
                sx={{
                  backgroundColor: "#34519fe6",
                  color: "#ffffff",
                }}
                size="small"
                onClick={handleApplyClick}
                // disabled={!startDate || !endDate}
              >
                Apply
              </Button>
            </Grid>
            {/*Reset Button */}  
            <Grid item xs={12} sm={0.7} md={0.7}>
              <Button
                variant="contained"
                startIcon={<RefreshIcon style={{ fontSize: "26px" }} />}
                size="small"
                sx={{
                  backgroundColor: "#34519fe6",
                  color: "#ffffff",
                  marginLeft: "5px"
                }}
                onClick={handleRefreshClick}
              >
                Reset
              </Button>
            </Grid>
             {/*Add New Crates Button */}
            <Grid item xs={12} sm={1.3} md={1.3}>
              <Button
                variant="contained"
                startIcon={
                  <AddCircleOutlineIcon
                    style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                  />
                }
                sx={{
                  borderColor: colors.green.DEFAULT,
                  backgroundColor: colors.green.DEFAULT,
                  marginLeft: "14px",
                  height: "35px",
                }}
                onClick={handleAddNewCrate}
                title="Add New Crates"
              >
                <Typography variant="h7" color={colors.white.DEFAULT}>
                  Add New Crates
                </Typography>
              </Button>
            </Grid>
      
             {/*Generate Barcodes Button */}
            <Grid item xs={12} sm={1.4} md={1.4}>
              <Button
                variant="contained"
                startIcon={
                  <FileDownloadIcon
                    style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                  />
                }
                sx={{
                  borderColor: colors.green.DEFAULT,
                  backgroundColor: colors.green.DEFAULT,
                  height: "35px",
                }}
                disabled={selectedRows.length === 0}
                onClick={handleGenerateBulkQR}
                title="Generate Barcodes"
              >
                <Typography variant="h7" color={colors.white.DEFAULT}>
                  Generate Barcodes
                </Typography>
              </Button>
            </Grid>

             {/*Download Button */}
            <Grid item xs={12} sm={1.0} md={1.0}>
              <Button
                variant="contained"
                startIcon={
                  <FileDownloadIcon
                    style={{ color: colors.white.DEFAULT, fontSize: 26 }}
                  />
                }
                sx={{
                  borderColor: colors.green.DEFAULT,
                  backgroundColor: colors.green.DEFAULT,
                  height: "35px",
                }}
                disabled
                onClick={handleGenerateReportClick}
                title="Download Crates"
              >
                <Typography variant="h7" color={colors.white.DEFAULT}>
                  Download
                </Typography>
              </Button>
            </Grid>
          
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={containerStyle8}>
        {isLoading ? (
          <div style={loaderContainerStyle}>
            <ScaleLoader color="#495057" loading={isLoading} />
          </div>
        ) : rows?.length === 0 ? (
          <NullScreen containerheight="70vh" containerWidth="100%" />
        ) : (
          <>
            <div style={gridContainerStyle}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 200 }} aria-label="customized table">
                  <TableHeadComponent
                  handleSelectAllClick={handleSelectAllClick}
                  isSelectedAll={isSelectedAll}
                 // isCheckboxEnabled={isCheckboxEnabled}
                  />
                  <TableRowsComponent
                    data1={rows}
                    status={status}
                    setStatus={setStatus}
                     selectedRows={selectedRows}
                    handleRowCheckboxClick={handleRowCheckboxClick}
                    //isCheckboxEnabled={isCheckboxEnabled}
                  />
                </Table>
              </TableContainer>
            </div>
            <Grid
              container
              justifyContent="flex-end"
              // style={{ marginTop: "5px" }}
            >
              <Pagination
                count={totalPages}
                page={offset}
                defaultPage={
                  JSON.parse(localStorage.getItem("filterValues"))?.[0]
                    ?.offset || offset
                }
                size="small"
                onChange={handleChangePage}
              />
            </Grid>
          </>
        )}
      </Grid>
       {bulkqr && (
            <GenerateBarCode barcodeData={selectedRows} onComplete={handleCompletion} />
          )}
                {openBulkQRDialog && (
        <AuditBulkQRGenerationProgresPopUp
          setOpenDialog={setOpenBulkQRDialog}
          soIds={selectedRows}
        />
      )}    
    </>
  );
};

export default AuditMainPage;
