import React from "react";
import { Grid, Stack, Divider, Typography } from "@mui/material";

const FooterWMS = () => {
  return (
    <Grid
      sx={{
        width: "100%",
        marginTop: "0.07%",
      }}
    >
      <Divider
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          marginTop: "0.12%",
          color: "#000000",
        }}
      >
        <Grid sx={{ fontWeight: "600" }}>
          <Typography
            component="span"
            sx={{
              "&:hover": {
                textDecoration: "none",
                cursor: "pointer",
              },
            }}
          >
            Copyright 2024
            <a
              href="https://www.fambo.in/"
              style={{
                color: "blue",
                textDecoration: "none",
                marginLeft: "7px",
              }}
            >
              Fambo Innovations Pvt. Ltd.
            </a>
          </Typography>
        </Grid>
        <Grid sx={{ fontWeight: "500" }}>
          All rights reserved <strong>Version 1.0.51</strong>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default FooterWMS;
